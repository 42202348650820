import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import Amplify, { Auth } from 'aws-amplify';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WelcomeModule } from './components/welcome/welcome.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardModule } from './components/dashboard/dashboard.module';
import { FontAwesomeLibModule } from '@app/shared/font-awesome-lib';
import { environment } from '@env/environment';
import { SharedModule } from '@app/shared/shared.module';
import { CookieService } from 'ngx-cookie-service';
import { GuardsModule } from '@app/guards/guards.module';
import { NgxMaskModule } from 'ngx-mask';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { QuillModule } from 'ngx-quill';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderInterceptor } from './interceptors/header.interceptor';
import { MatNativeDateModule } from '@angular/material/core';

Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: 'us-east-1',
        userPoolId: environment.userPoolId,
        userPoolWebClientId: environment.userPoolWebClientId,
        authenticationFlowType: 'USER_PASSWORD_AUTH'
    }
});

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        NgbModule,
        NoopAnimationsModule,
        WelcomeModule,
        DashboardModule,
        FontAwesomeLibModule,
        SharedModule,
        GuardsModule,
        MatNativeDateModule,
        NgxMaskModule.forRoot(),
        BrowserAnimationsModule,
        ToastrModule.forRoot({ positionClass: 'toast-top-center' }),
        QuillModule.forRoot(),
        ScrollingModule
    ],
    providers: [
        CookieService,
        {provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true}
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
