export enum NotificationSearchType {
    /**
     * Used to Search Messages sent by employers (master records)
     */
    BULK = 'BULK',
    /**
     * Used to Search Notification Requests for Pending Employer Request
     */
    PENDING_EMPLOYER_REQUEST = 'PENDING_EMPLOYER_REQUEST',
    /**
     * Used To Search Messages sent to Members (bulk or registration)
     */
    MEMBER = 'MEMBER'
}