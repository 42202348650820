import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TitleService } from '@app/services/title.service';
import { environment } from '@env/environment';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { Subject, timer } from 'rxjs';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';

const has = Object.prototype.hasOwnProperty;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  constructor(private titleService: TitleService, private router: Router, private gaService: GoogleAnalyticsService) {
    if (environment.gaTrackingId) {
      // register google tag manager
      const gTagManagerScript = document.createElement('script');
      gTagManagerScript.async = true;
      gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.gaTrackingId}`;
      document.head.appendChild(gTagManagerScript);

      // register google analytics
      const gaScript = document.createElement('script');
      gaScript.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag() { dataLayer.push(arguments); }
          gtag('js', new Date());
          gtag('config', '${environment.gaTrackingId}');
        `;
      document.head.appendChild(gaScript);
    }
  }

  ngOnInit() {
    timer(500)
      .pipe(
        filter(() => has.call(window, 'ga')),
        take(1),
        switchMap(() => {
          return this.router.events.pipe(
            filter((e) => e instanceof NavigationEnd),
            tap((e: NavigationEnd) => {
              this.gaService.logPageView(e.url);
            })
          );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
  title = 'Employer';
}
