export enum MessageType {
    /**
     * SMS
     */
    S = 'SMS',

    /**
     * Email
     */
    E = 'Email'
}