import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { BAD_CREDENTIALS, DISABLED, NOT_CERTIFICATED, NOT_VERIFIED, RECAPTCHA_ERROR } from '@app/constants/ErrorMessages';
import { Path } from '@app/constants/Path';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Auth } from 'aws-amplify';
import { Router } from '@angular/router';
import { RecaptchaComponent } from 'ng-recaptcha';
import { CaptchaService } from '@app/services/captcha.service';
import { Subscription } from 'rxjs';
import { AppCookieService } from '@app/services/app-cookie.service';
import { ToastrService } from 'ngx-toastr';
import { GoogleAnalyticsService } from '@app/services/google-analytics.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

    readonly Path = Path;

    errorMessage: string;
    DISABLED = DISABLED;
    NOT_VERIFIED = NOT_VERIFIED;
    BAD_CREDENTIALS = BAD_CREDENTIALS;
    RECAPTCHA_ERROR = RECAPTCHA_ERROR;
    loading = false;
    submitted = false;
    showCaptcha = false;
    message: string;
    siteKey = "6LdFn8oaAAAAAJfE-spYyvWDeELP1PT5v9lLHfnC";
    invalidLogins = 0;
    hasFailedLogin = false;
    showPassword = false;

    private checkCaptchaSubscription: Subscription = null;

    @ViewChild(RecaptchaComponent, { static: false }) recaptchaComponent: RecaptchaComponent;

    loginForm = this.fb.group({
        username: ['', Validators.required],
        password: ['', Validators.required],
        recaptcha: ['']
    });
    fc = this.loginForm.controls;

    constructor(private fb: FormBuilder, private router: Router, private captchaService: CaptchaService, private cookie: AppCookieService, private toastr: ToastrService
        , private gaService: GoogleAnalyticsService) {
    }

    private recaptchaResponse: string;

    ngOnInit(): void {
        if (this.router.url.includes(Path.LOGOUT)) {
            this.logout();
        }
        if (this.cookie.getCookie("resetPasswordSucess")) {
            this.toastr.success("Password Reset", "");
            this.cookie.deleteAllCookies();
        }
    }

    ngOnDestroy() {
        this.checkCaptchaSubscription && this.checkCaptchaSubscription.unsubscribe();
    }

    async loginWithCognito() {

        this.loading = true;
        this.gaService.logEvent("Login", "login", "Login");
        if (Number(this.invalidLogins > Number(0))) {
            let req = { "validationData": this.recaptchaResponse };
            this.checkCaptchaSubscription = this.captchaService.checkCaptcha(req)
                .subscribe(data => {
                    if (data.success === true) { //TODO: check this
                        this.doAuth();
                    } else {
                        this.resetCaptcha();
                    }
                });
        } else {
            this.doAuth();
        }
    }

    async doAuth() {
        try {
            let member = this.loginForm.value;
            var user = await Auth.signIn(member.username, member.password);
            var tokens = user.signInUserSession;
            if (tokens != null) {
                let employerString = user.attributes["custom:organization"];
                employerString = employerString.split('_').join(' ');

                this.cookie.setCookie('tokenExp', tokens.idToken.payload.exp);
                this.cookie.setCookie('token', tokens.idToken.jwtToken);
                this.cookie.setCookie('employer', employerString);
                this.cookie.setCookie('name', user.attributes["given_name"] + " " + user.attributes["family_name"]);
                this.cookie.setCookie('email', user.attributes['email']);
                this.loading = false;
                this.router.navigate(['dashboard']);
            }
        } catch (error) {
            console.log(error.name);
            this.invalidLogins++;
            this.resetCaptcha();
            this.showCaptcha = true;
            this.hasFailedLogin = true;
            this.loading = false;
        }
    }

    async signOut() {
        try {
            await Auth.signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    getValidUsernameOrEmpty(): string {
        return this.fc.username.valid ? this.loginForm.value.username : '';
    }

    resolvedRecaptcha(response: string) {
        this.recaptchaResponse = response;
        this.errorMessage = this.errorMessage === this.RECAPTCHA_ERROR ? '' : this.errorMessage;
    }

    private resetCaptcha() {
        if (this.recaptchaComponent) {
            this.recaptchaComponent.reset();
            this.recaptchaResponse = null;
        }
    }

    toggleShowPassword() {
        this.showPassword = !this.showPassword;
    }

    private logout() {
        this.signOut();
        this.cookie.deleteAllCookies();
        this.router.navigate(['login']);
    }
}