import { NgModule } from '@angular/core';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faCaretSquareLeft } from '@fortawesome/free-solid-svg-icons/faCaretSquareLeft';
import { faUserCog } from '@fortawesome/free-solid-svg-icons/faUserCog';
import { faExclamationTriangle, faFileExport, faFileUpload, faPlusCircle, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { faFrown } from '@fortawesome/free-regular-svg-icons/faFrown';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons/faUserEdit';
import { faKey } from '@fortawesome/free-solid-svg-icons/faKey';
import { faAt } from '@fortawesome/free-solid-svg-icons/faAt';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faListAlt } from '@fortawesome/free-solid-svg-icons/faListAlt';
import { faCircle as faCircleRegular } from '@fortawesome/free-regular-svg-icons/faCircle';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons/faFileAlt';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faCreativeCommonsZero } from '@fortawesome/free-brands-svg-icons/faCreativeCommonsZero';
import { faCoffee, fas } from '@fortawesome/free-solid-svg-icons';

@NgModule({
  imports: [
    FontAwesomeModule
  ],
  exports: [
    FontAwesomeModule
  ],
})
export class FontAwesomeLibModule {
//  constructor() {
//    library.add(
//      faEnvelope,
//      faCaretSquareLeft,
//      faExclamationTriangle,
//      faExclamationCircle,
//      faCalendar,
//      faQuestionCircle,
//      faFileExport,
//      faFileUpload,
//      faPlusCircle,
//      faLink,
//      faFrown,
//      faUserEdit,
//      faAt,
//      faKey,
//      faUserCog,
//      faEdit,
//      faListAlt,
//      faCircleRegular,
//      faCircle,
//      faFileAlt,
//      faCheckCircle,
//      faTimesCircle,
//      faMinus,
//      faCreativeCommonsZero
//    );
//  }
  constructor(library: FaIconLibrary) {
       library.addIconPacks(fas);
       library.addIcons(faCoffee);
       library.addIcons(faEnvelope);
       library.addIcons(faCaretSquareLeft);
       library.addIcons(faExclamationTriangle);
       library.addIcons(faExclamationCircle);
       library.addIcons(faCalendar);
       library.addIcons(faQuestionCircle);
       library.addIcons(faFileExport);
       library.addIcons(faFileUpload);
       library.addIcons(faPlusCircle);
       library.addIcons(faLink);
       library.addIcons(faFrown);
       library.addIcons(faUserEdit);
       library.addIcons(faAt);
       library.addIcons(faKey);
       library.addIcons(faUserCog);
       library.addIcons( faEdit);
       library.addIcons(faListAlt);
       library.addIcons(faCircleRegular);
       library.addIcons(faCircle);
       library.addIcons(faFileAlt);
        library.addIcons( faCheckCircle);
        library.addIcons( faTimesCircle);
        library.addIcons( faMinus);
        library.addIcons(faCreativeCommonsZero);
   } 
}
