import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppCookieService } from '@app/services/app-cookie.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MemberService } from '@app/services/member.service';
import { MessageService } from '@app/services/message.service';
import { NotificationService } from '@app/services/notification.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from '@app/shared/confirm-dialog/confirm-dialog.component';
import { PhonePipe } from '@app/shared/pipes/phone.pipe';
import { StateService } from '@app/services/state.service';
import { StateModel } from '@app/models/state.model';
import { ToastrService } from 'ngx-toastr';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import { CountyModel } from '@app/models/county.model';
import { NotificationSearchModel } from '@app/models/notification-search.model';

@Component({
    selector: 'app-member-detail',
    templateUrl: './member-detail.component.html',
    styleUrls: ['./member-detail.component.scss']
})
export class MemberDetailComponent implements OnInit {
    loading = true;

    //TODO Get member retrieve id from parent component
    //    @Input member;

    emailPreference = '';
    smsPreference = '';
    memberName = '';

    isEditEnabled = false;
    emailPattern = "^.{1,64}@[^\.].*[\.].*$";
    nameMaxLength = 50;
    emailMaxLength = 256;
    initialFormVals = {};

    memberForm = this.fb.group({
        firstName: this.fb.control('', [Validators.required, Validators.maxLength(this.nameMaxLength)]),
        lastName: this.fb.control([''], [Validators.required, Validators.maxLength(this.nameMaxLength)]),
        email: this.fb.control([''], [Validators.required, Validators.maxLength(this.emailMaxLength), Validators.pattern(this.emailPattern)]),
        phone: this.fb.control("", [Validators.required]),
        state: this.fb.control(["Mississippi"], [Validators.required]),
        county: this.fb.control(["Adams"], [Validators.required]),
        preference: this.fb.control('', [Validators.required])
    });

    // TODO move to models file
    member = {
        "first_name": "", "last_name": "", "preference": "", "registration_date": "", "phone": "", "state": "", "fips_code": "", "email": "", "creation_date": "",
        "unsub_email": "", "unsub_email_date": "", "unsub_phone": "", "unsub_phone_date": "", "twilio_block": "", "skey": "", "id": "", "employer_name": ""
    };

    stateOptions = [];
    states: StateModel[];
    countyOptions = [];
    countyString = '';
    preferenceOptions = [{ 'code': 'E', 'value': "Email" }, { 'code': 'S', 'value': "SMS" }, { 'code': 'B', 'value': "Email & SMS" }];

    isEmailTaken = false;
    isPhoneTaken = false;
    isMemberTaken = false;
    isSystemError = false;
    isPhoneInvalid = false;
    requestInProgress = false;
    hideSendMessage = true;

    unsub_phone = null;
    twilio_block = null;
    unsubscribe_phone_date = null;
    unsub_email = null;
    unsubscribe_email_date = null;
    lastEvalArr: any[];
    LastEvaluatedKey: any;

    @ViewChild(CdkVirtualScrollViewport)
    viewport: CdkVirtualScrollViewport;
    theEnd = false;
    searchingMessages = true;
    messages = { "Items": [] };

    constructor(private route: ActivatedRoute, private router: Router, private cookie: AppCookieService, private memberService: MemberService, private messageService: MessageService, private modalService: NgbModal,
        private notificationService: NotificationService, private stateService: StateService, private fb: FormBuilder, private toastr: ToastrService, private gaService: GoogleAnalyticsService) { }

    ngOnInit(): void {
        this.getMemberDetails();
        this.getMemberMessages();
        this.registerFormChanges();

        this.stateService.getAll().subscribe(states => {
            this.states = states;
            this.stateOptions = states.map(state => { return { "id": state.code, "name": state.description } });
        });
    }

    initializeForm() {
        this.memberForm.controls["firstName"].setValue(this.member.first_name);
        this.memberForm.controls["lastName"].setValue(this.member.last_name);
        this.memberForm.controls["phone"].setValue(this.removeCountryCodeIfExists(this.member.phone));
        this.memberForm.controls["email"].setValue(this.member.email);
        this.memberForm.controls["state"].setValue(this.member.state);
        this.memberForm.controls["county"].setValue(this.member.fips_code);
        this.memberForm.controls["preference"].setValue(this.member.preference);

        Object.keys(this.memberForm.controls).forEach(key => {
            this.initialFormVals[key] = this.memberForm.controls[key].value;
        });

        this.memberForm.markAsPristine();
    }

    removeCountryCodeIfExists(phone: string) {
        if (phone.startsWith('+1')) {
            return phone.substring(2);
        }
        return phone;
    }

    registerFormChanges() {
        this.memberForm.controls["state"].valueChanges.subscribe(val => this.onStateValueChange(val));
    }

    onStateValueChange(stateCode) {
        this.findAndAssignCountyOptions(stateCode);
    }

    findAndAssignCountyOptions(stateCode: string) {
        if (!!this.states) {
            this.states.forEach(state => {
                if (state.code === stateCode) {
                    if (state.hasOwnProperty("counties") && state.counties.length > 0) {
                        this.countyOptions = state.counties.map(county => {
                            return {
                                "fipsCode": county.fipsCode,
                                "description": county.description
                            }
                        });
                        this.memberForm.controls["county"].setValue(state.counties[0].fipsCode);
                    } else {
                        this.countyOptions = [];
                        this.memberForm.controls["county"].setValue(state.fipsCode);
                    }
                }
            });
        }
    }

    getMemberDetails() {
        let skey = this.cookie.getCookie('member-skey');

        let search = {
            "skey": skey,
            "searchType": "GET"
        }

        this.memberService.searchMember(search).subscribe(response => {
            this.member = response.Item;
            this.findAndAssignCountyOptions(this.member.state);
            this.initPersonalInfoSection();
            this.initializeForm();
            this.setHideSendMessage();
            this.loading = false;
        });
    }

    getMemberMessages() {
        const skey = this.cookie.getCookie('member-skey');
        // let messageRequest = { "skey": skey };
        const messageRequest = new NotificationSearchModel();
        messageRequest.skey = skey;
        if (this.LastEvaluatedKey)
            messageRequest.ExclusiveStartKey = this.LastEvaluatedKey;
        this.searchingMessages = true;

        this.notificationService.searchMemberNotifications(messageRequest).subscribe(response => {
            this.LastEvaluatedKey = response.LastEvaluatedKey;
            this.messages.Items = [];
            response.Items.forEach(message => {
                let m = {};
                m["date"] = message.sent_date.split("T")[0];
                m["type"] = message.send_mode === "E" ? "Email" : "SMS";
                m["body"] = message.content ? message.content : "";
                this.messages.Items.push(m);
            })

            this.theEnd = undefined === response.LastEvaluatedKey;
            this.searchingMessages = false;
        });
    }

    initPersonalInfoSection(): void {
        this.member.first_name = this.makeTitleCase(this.member.first_name);
        this.member.last_name = this.makeTitleCase(this.member.last_name);
        this.memberName = this.member.first_name + ' ' + this.member.last_name;
        this.countyString = this.getMemberCountyString();
        this.emailPreference = this.member.preference === 'B' || this.member.preference === 'E' ? 'Yes' : 'No';
        this.smsPreference = this.member.preference === 'B' || this.member.preference === 'S' ? 'Yes' : 'No';
        this.unsub_phone = this.member.unsub_phone;
        this.unsubscribe_phone_date = this.member.unsub_phone_date;
        this.unsub_email = this.member.unsub_email;
        this.unsubscribe_email_date = this.member.unsub_email_date;
        this.twilio_block = this.member.twilio_block;
    }

    getMemberCountyString() {
        for (const county in this.countyOptions) {
            const curCounty = this.countyOptions[county];
            if (curCounty.fipsCode === this.member.fips_code) {
                return curCounty.description;
            }
        }
        return '';
    }

    setHideSendMessage(): void {
        if (this.emailPreference === 'Yes' && this.smsPreference === 'Yes') {
            this.hideSendMessage = this.unsub_email && (this.unsub_phone || this.twilio_block);
        }
        else if (this.emailPreference === 'Yes') {
            this.hideSendMessage = this.unsub_email;
        }
        else if (this.smsPreference === 'Yes') {
            this.hideSendMessage = (this.unsub_phone || this.twilio_block);
        }
        else {
            this.hideSendMessage = false;
        }
    }

    toggleEditMode() {
        this.isEditEnabled = !this.isEditEnabled;
        if (this.isEditEnabled) {
            this.initializeForm();
        }
    }

    makeTitleCase(input: string) {
        if (input.length < 1)
            return "";
        if (input.length === 1) {
            return input.toUpperCase();
        } else {
            return input[0].toUpperCase() + input.substr(1).toLowerCase();
        }
    }

    onSubmit() {
        if (this.formIsClean()) {
            return this.cancelEdit();
        }

        let updated = this.getUpdatedMember();
        this.requestInProgress = true;

        this.gaService.logEvent("MemberDetails", "EditDetails", "Save Changes");
        this.memberService.editMember(updated).subscribe(response => {

            if (response.error) {
                this.handleEditMemberError(response);
            } else {
                this.handleEditMemberSuccess(updated);
                this.setHideSendMessage();
            }

            this.requestInProgress = false;
        });
    }

    handleEditMemberSuccess(updated) {

        this.setMemberInfoUpdate(updated);
        this.initPersonalInfoSection();
        this.toggleEditMode();
        this.toastr.success('Member changes saved!', '');

    }

    handleEditMemberError(response) {
        this.isEmailTaken = false;
        this.isPhoneTaken = false;
        this.isMemberTaken = false;
        this.isSystemError = false;
        this.isPhoneInvalid = false;
        if (response.error && response.error.message) {
            // known errors
            if (response.error.message.includes("The email is already taken")) {
                this.memberForm.controls['email'].setErrors({ "taken": true });
                this.isEmailTaken = true;
            }
            if (response.error.message.includes("The phone is already taken")) {
                this.memberForm.controls['phone'].setErrors({ "taken": true });
                this.isPhoneTaken = true;
            }
            if (response.error.message.toUpperCase().includes('INVALID PHONE NUMBER')) {
                this.memberForm.controls['phone'].setErrors({'invalid': true});
                this.isPhoneInvalid = true;
            }
            if (response.error.message.includes("The member is already taken")) {
                this.isMemberTaken = true;
            }
            if (!(this.isEmailTaken || this.isPhoneTaken || this.isMemberTaken || this.isPhoneInvalid)) {
                this.isSystemError = true;
            }
        } else {
            //unknown error
            this.isSystemError = true;
        }

    }

    setMemberInfoUpdate(update) {
        this.member.first_name = update.firstName;
        this.member.last_name = update.lastName;
        this.member.phone = update.phone;
        this.member.email = update.email;
        this.member.state = update.state;
        this.member.fips_code = update.fipsCode;
        this.member.preference = update.preference;
        this.member.registration_date = update.registrationDate;
        this.member.creation_date = update.createdDate;
        this.member.employer_name = update.employerName;
        this.member.skey = this.getNewSkey(update);
        this.cookie.setCookie('member-skey', this.member.skey);
    }

    getNewSkey(member) {
        return member.employerName + '#' + member.firstName.toUpperCase() + '#' + member.lastName.toUpperCase() + '#' + member.state + '#' + member.fipsCode + '#' + member.phone + '#' + member.email + '#' + member.preference + '#' + member.registrationDate;
    }

    getUpdatedMember() {
        let updated = {};
        updated["firstName"] = this.memberForm.get("firstName").value.toUpperCase();
        updated["lastName"] = this.memberForm.get("lastName").value.toUpperCase();
        updated["phone"] = this.addCountryCodeIfMissing(this.memberForm.get("phone").value);
        updated["email"] = this.memberForm.get("email").value;
        updated["state"] = this.memberForm.get("state").value;
        updated["fipsCode"] = this.memberForm.get("county").value;
        updated["preference"] = this.memberForm.get("preference").value;
        updated["id"] = this.member.id;
        updated["skey"] = this.member.skey;
        updated["registrationDate"] = this.member.registration_date;
        updated["prevemail"] = this.member.email;
        updated["prevphone"] = this.member.phone;
        updated["prevpref"] = this.member.preference;
        updated["prevFips"] = this.member.fips_code;
        updated["createdDate"] = this.member.creation_date;
        updated["unsubscribeEmail"] = this.member.unsub_email;
        updated["unsubEmailDate"] = this.member.unsub_email_date;
        updated["unsubscribePhone"] = this.member.unsub_phone;
        updated["unsubPhoneDate"] = this.member.unsub_phone_date;
        updated["twilioBlock"] = this.member.twilio_block;
        updated["employerName"] = this.member.employer_name;
        return updated;
    }
    
    addCountryCodeIfMissing(phone: string) {
        return !phone.startsWith('+1') ? '+1' + phone : phone;
    }

    cancelEdit() {
        this.toggleEditMode();
        this.initializeForm();
    }

    deleteMember() {
        let deletedMember = [];
        deletedMember.push(this.member);
        this.requestInProgress = true;
        this.gaService.logEvent("MemberDetails", "DeleteMember", "Yes");
        this.memberService.deleteMembers(deletedMember).subscribe(response => {
            this.cookie.setCookie("memberRemoved", "true");
            this.requestInProgress = false;
            this.router.navigateByUrl('/dashboard');
        });
    }

    confirmDelete() {
        const confirmDialog = this.modalService.open(ConfirmDialogComponent, { centered: true });
        confirmDialog.componentInstance.pageTitle = "";
        confirmDialog.componentInstance.pageBody = "Are you sure you want to remove " + this.memberName + " from your talent community?";
        confirmDialog.componentInstance.showCloseButton = false;
        confirmDialog.result.then(() => {
            this.deleteMember();
        }, () => undefined);
    }

    scrollHandler(event, member) {
        if (member === undefined || this.theEnd)
            return;

        const end = this.viewport.getRenderedRange().end;
        const total = this.viewport.getDataLength();

        if (end === total && !this.theEnd) {
            this.getMemberMessages();
        }
    }

    reinitializeVariables() {
        this.lastEvalArr = [];
        this.LastEvaluatedKey = null;
        this.theEnd = false;
        this.messages.Items = [];
    }

    formIsClean() {
        if (this.memberForm.pristine) {
            return true;
        } else {
            let clean = true;
            Object.keys(this.memberForm.controls).forEach(key => {
                if (this.memberForm.controls[key].value !== this.initialFormVals[key]) {
                    clean = false;
                }
            });
            return clean;
        }
    }
}
