import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Auth } from 'aws-amplify';
import { Router } from '@angular/router';
import { AppCookieService } from '@app/services/app-cookie.service';
import { GoogleAnalyticsService } from '@app/services/google-analytics.service';


@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

    submitted = false;
    emailPattern = "^.{1,64}@[^\.].*[\.].*$";
    requestError = false;
    requestInProgress = false;

    forgotPasswordForm = this.fb.group({
        email: ['', [Validators.required, Validators.pattern(this.emailPattern)]]
    });

    constructor(private fb: FormBuilder, private router: Router, private cookie: AppCookieService
        , private gaService: GoogleAnalyticsService) { }

    ngOnInit(): void {
    }

    submit() {

        this.gaService.logEvent("ForgotPassword", "sendCode", "Email My Code");
        this.requestInProgress = true;
        Auth.forgotPassword(this.forgotPasswordForm.controls["email"].value)
            .then(data => this.handleSuccess(data))
            .catch(err => this.handleError(err));

    }

    handleSuccess(data: any) {
        this.requestInProgress = false;
        this.cookie.setCookie("username", this.forgotPasswordForm.controls["email"].value);
        this.cookie.setCookie("passwordEmailSent", "true");
        this.router.navigateByUrl('/reset');
    }

    handleError(err: any) {
        this.requestInProgress = false;
        this.requestError = true;
    }

}
