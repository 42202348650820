import { Injectable, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomDateAdapter {
  fromModel(value: any): NgbDateStruct {
    if (!value) return null;
    if (typeof value === 'object' && value.year) return value;
    if (typeof value === 'object' && !value.year) return null;

    let parts = value.split('-');
    let date = {
      year: +parts[0],
      month: +parts[1],
      day: +parts[2],
    } as NgbDateStruct;
    return date;
  }

  toModel(date: NgbDateStruct): string {
    let date2 = date ? date.year + '-' + ('0' + date.month).slice(-2) + '-' + ('0' + date.day).slice(-2) : null;
    return date2;
  }
}
@Injectable()
export class CustomDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (!value) return null;
    let separator = value.indexOf('-') > -1 ? '-' : '/';
    let parts = value.split(separator);
    let date = {
      year: +parts[2],
      month: +parts[0],
      day: +parts[1],
    } as NgbDateStruct;
    return date;
  }
  format(date: NgbDateStruct): string {
    let date2 = date ? ('0' + date.month).slice(-2) + '/' + ('0' + date.day).slice(-2) + '/' + date.year : null;
    return date2;
  }
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  exports: [],
  providers: [
    CustomDateAdapter,
    CustomDateParserFormatter
  ]
})
export class CustomDateFormatterParserModule { }