import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators, ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { AppCookieService } from '@app/services/app-cookie.service';
import { ToastrService } from 'ngx-toastr';
import { GoogleAnalyticsService } from '@app/services/google-analytics.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

    showPassword = false;
    showConfirmPassword = false;
    isLinkExired = false;
    passwordPattern = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&+=]).*$";
    requestError = false;
    isCodeExpired = false;
    isCodeInvalid = false;
    isUserNotFound = false;
    isLimitExceeded = false;
    requestInProgress = false;
    resendRequestInProgress = false;

    resetPasswordForm = this.fb.group({
        username: ['', [Validators.required]],
        code: ['', [Validators.required]],
        newPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(30), Validators.pattern(this.passwordPattern), this.passwordHasNotSpace()]],
        confirmPassword: ['']
    });

    constructor(private fb: FormBuilder, private router: Router, private cookie: AppCookieService, private toastr: ToastrService, private gaService: GoogleAnalyticsService) { }
    username = null;
    usernameProvided = false;

    ngOnInit(): void {
        //TODO check if link is expired
        this.isLinkExired = this.checkisLinkExpired();

        this.username = this.cookie.getCookie("username");

        if (this.username) {
            this.resetPasswordForm.controls.username.setValue(this.username);
            this.usernameProvided = true;
        }
        this.resetPasswordForm.controls.confirmPassword.setValidators([Validators.required]);
        this.resetPasswordForm.controls.confirmPassword.updateValueAndValidity();

        if (this.cookie.getCookie("passwordEmailSent") === "true") {
            this.toastr.success("Verification Code Sent", "");
        }
    }

    checkisLinkExpired() {
        //TODO implement logic
        return true;
    }

    submit() {
        let username = this.resetPasswordForm.controls.username.value;
        let code = this.resetPasswordForm.controls.code.value;
        let new_password = this.resetPasswordForm.controls.newPassword.value;


        this.requestInProgress = true;
        Auth.forgotPasswordSubmit(username, code, new_password)
            .then(data => this.handleSuccess(data))
            .catch(err => this.handleError(err));
        this.gaService.logEvent("ResetPassword", "ChangePassword", "Change Password");

    }

    handleSuccess(data: any) {
        this.cookie.deleteAllCookies();
        this.requestInProgress = false;
        this.cookie.setCookie("resetPasswordSucess", "true");
        this.router.navigateByUrl('/login');
    }

    handleError(err: any) {
        this.isCodeExpired = false;
        this.isCodeInvalid = false;
        this.isUserNotFound = false;
        this.isLimitExceeded = false;
        this.requestError = true;
        if (err.code === "ExpiredCodeException")
            this.isCodeExpired = true;
        if (err.code === "CodeMismatchException")
            this.isCodeInvalid = true;
        if (err.code === "UserNotFoundException")
            this.isUserNotFound = true;
        if (err.code === "LimitExceededException")
            this.isLimitExceeded = true;

        this.requestInProgress = false;

    }

    resendCode() {
        this.gaService.logEvent("ResetPassword", "ResendCodeEmail", "here");

        this.resendRequestInProgress = true;
        Auth.forgotPassword(this.resetPasswordForm.controls.username.value)
            .then(data => {
                this.resendRequestInProgress = false;
                this.toastr.success("Email Sent", "");
            })
            .catch(err => {
                this.resendRequestInProgress = false;
            });

    }

    passwordHasNotSpace(): ValidatorFn {
        const HAS_SPACE = /[ ]/;
        return (control: AbstractControl): { [key: string]: any } => {
            if (!control.value) {
                return null;
            }
            const invalid = HAS_SPACE.test(control.value);
            return invalid ? { hasNotSpace: true } : null;
        };
    }

    updateConfirmPasswordValidation() {
        const password = this.resetPasswordForm.controls.newPassword.value;
        const verifyPassword = this.resetPasswordForm.controls.confirmPassword.value;
        if (password !== verifyPassword) {
            this.resetPasswordForm.controls.confirmPassword.setErrors({ noMatch: true });
        } else {
            this.resetPasswordForm.controls.confirmPassword.updateValueAndValidity();
        }
    }

    toggleShowPassword() {
        this.showPassword = !this.showPassword;
    }

    toggleShowConfirmPassword() {
        this.showConfirmPassword = !this.showConfirmPassword;
    }

}
