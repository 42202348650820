import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
    
    constructor(private cookie: CookieService, private router: Router){      
    }   
    
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      if ( !this.cookie.get('token') ) {
          this.router.navigate(['login']);
          return false;
      }
      return true;
  }
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      return this.canActivate(route, state);
  }
  
}
