import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { DashboardBaseComponent } from './dashboard-base/dashboard-base.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './header/header/header.component';
import { FontAwesomeLibModule } from '@app/shared/font-awesome-lib';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MemberDetailComponent } from './member-detail/member-detail.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MessagesComponent } from './messages/messages.component';
import { CreateMessageComponent } from './create-message/create-message.component';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from '@app/shared/shared.module';
import { QuillModule } from 'ngx-quill';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HeatmapComponent } from './heatmap/heatmap.component';
import { MessageToggleComponent } from './message-toggle/message-toggle.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
    entryComponents: [],
    declarations: [
        DashboardBaseComponent,
        HeaderComponent,
        DashboardComponent,
        MemberDetailComponent,
        MessagesComponent,
        CreateMessageComponent,
        HeatmapComponent,
        MessageToggleComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        DashboardRoutingModule,
        BrowserAnimationsModule,
        MatButtonToggleModule,
        MatInputModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatSelectModule,
        MatNativeDateModule,
        MatSidenavModule,
        MatExpansionModule,
        FontAwesomeLibModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        NgxMaskModule,
        NgbModule,
        SharedModule,
        QuillModule,
        ScrollingModule
    ],
    providers: [TitleCasePipe],
    exports: [DashboardBaseComponent]
})
export class DashboardModule {
}
