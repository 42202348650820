import { Component, Input, OnInit } from '@angular/core';
import { Path } from '@app/constants/Path';
import { DashboardUserModel } from '../../dashboard-user.model';
import { DATE_TIME_FORMAT } from '@app/constants/text.constant';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() principal: DashboardUserModel;
  @Input() isMobile: boolean;

  logoutUrl = `/${Path.LOGOUT}`;
  DATE_TIME_FORMAT = DATE_TIME_FORMAT;
  unreadCount = 0;
  allowedViewMessages = false;

  constructor() {
  }

  ngOnInit() {   
  }


}
