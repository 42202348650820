import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Endpoint } from '@app/constants/Endpoint';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private http: HttpClient) { }

  sendBulkMessage(message) {
    if (message.messageType === 'E') {
      return this.http.post<any>(environment.host + `${Endpoint.NOTIFICATION}${Endpoint.EMAIL}`, message);
    } else {
      return this.http.post<any>(environment.host + `${Endpoint.NOTIFICATION}${Endpoint.SMS}`, message);
    }
  }
}
