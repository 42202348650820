import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LandingComponent } from './landing/landing.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AnonymousGuard } from './../../guards/anonymous.guard';

import { Path } from '../../constants/Path';

const routes: Routes = [
    {
        path: "",
        component: LandingComponent,
        data: { title: 'Welcome' },
        canActivate: [AnonymousGuard],
        //    canActivateChild: [AnonymousGuard],
        resolve: {
        },

        children: [
            {
                path: Path.LOGIN,
                component: LoginComponent,
                data: { title: 'Login' },
            },
            {
                path: Path.LOGOUT,
                component: LoginComponent,
                data: { title: 'Logout' },
            },
            {
                path: Path.FORGOT_PASSWORD,
                component: ForgotPasswordComponent,
                data: { title: 'Forgot Password' }
            },
            {
                path: Path.RESET_PASSWORD,
                component: ResetPasswordComponent,
                data: { title: 'Reset Password' }
            }
        ]
    }];

@NgModule( {
    imports: [RouterModule.forChild( routes )],
    exports: [RouterModule]
} )
export class WelcomeRoutingModule {
}
