import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable( {
    providedIn: 'root'
} )
export class AppCookieService {

    constructor( private cookie: CookieService ) { }

    public setCookie( key: string, value: string ) {
        this.cookie.set( key, value, null, null, null, null, null );
    }

    public getCookie( key: string ) {
        return this.cookie.get( key );
    }

    public deleteAllCookies() {

        this.cookie.delete( 'token', '/' );
        this.cookie.delete( 'member-fn' );
        this.cookie.delete( 'member-ln' );
        this.cookie.delete( 'member-phone' );
        this.cookie.delete( 'member-email' );
        this.cookie.delete( 'member-state' );
        this.cookie.delete( 'member-fipsCode' );
        this.cookie.delete( 'member-pref' );
        this.cookie.delete( 'member-regDate' );
        this.cookie.delete( 'member-createdDate' );
        this.cookie.delete( 'member-id' );
        this.cookie.delete( 'member-skey' );
        this.cookie.delete( 'member-unsubscribeEmail' );
        this.cookie.delete( 'member-unsubEmailDate' );
        this.cookie.delete( 'member-unsubscribePhone' );
        this.cookie.delete( 'member-unsubPhoneDate' );
        this.cookie.delete( 'member-twilioBlock' );
        this.cookie.delete( 'employer' );
        this.cookie.delete( 'name' );
        this.cookie.delete( 'email' );
        this.cookie.delete( 'username' );
        this.cookie.delete( 'passwordEmailSent' );
        this.cookie.delete( 'resetPasswordSucess' );
        this.cookie.delete( 'memberRemoved' );
        this.cookie.deleteAll( '/' );
    }

}
