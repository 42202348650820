<div class="login-container">
	<div class="border-container col-10 mx-auto">
		<div class="pb-2 mt-4 mb-2 text-center">
			<img width="40" alt="Angular Logo" src="assets/employer_logo.svg" />
			<h4 class="app-blue font-weight-bold">Login to Employer
				Dashboard</h4>
		</div>
		<div class="row justify-content-center">
			<div [hidden]="!hasFailedLogin" class="alert alert-danger col-sm-8 ">
				<div>Login failed. Please check that the below information is
					correct.</div>
			</div>
		</div>
		<div class="row">
			<form [formGroup]="loginForm" (submit)="loginWithCognito()"
				class="m-auto col-sm-8">
				<div *ngIf="message" class="alert alert-success" role="alert">
					{{message}}</div>
				<fieldset [disabled]="loading">
					<legend class="sr-only">Please, provide login information
						by entering username and password for login</legend>
					<div class="form-group mt-3 required">
						<label for="username">Username</label> <input [autofocus]="true"
							formControlName="username" class="form-control" id="username"
							type="email">
						<div
							[hidden]="loginForm.controls.username.status==='VALID' || loginForm.controls.username.pristine"
							class="alert alert-danger col mb-0">
							<div *ngIf="loginForm.controls.username.errors?.required">Username
								is required</div>
						</div>
					</div>
					<div class="form-group mt-4 required">
						<label for="password">Password</label>
						<div class="input-group-append">
							<input formControlName="password" class="form-control"
								id="password" [type]="showPassword ? 'text' : 'password'">
							<small class="text-danger"> </small>
							<div class="input-group-append" (click)="toggleShowPassword()">
								<span class="input-group-text"> 
									<i class="fa" [ngClass]="showPassword?'fa-eye':'fa-eye-slash'"></i>
								</span>
							</div>
						</div>
						<div
							[hidden]="loginForm.controls.password.status==='VALID' || loginForm.controls.password.pristine"
							class="alert alert-danger col mb-0">
							<div *ngIf="loginForm.controls.password.errors?.required">Password
								is required</div>
						</div>
					</div>
				</fieldset>
				<div *ngIf="showCaptcha" class="form-group">
					<div class="login-recaptcha">
						<re-captcha siteKey="{{siteKey}}" formControlName="recaptcha"
							(resolved)="resolvedRecaptcha($event)"> </re-captcha>
					</div>
				</div>
				<div class="row">
					<div class="button col-sm-7 mx-auto">
						<button type="submit" [disabled]="loginForm.invalid" [promiseBtn]="loading"
							class="btn btn-primary btn-block pointer-cursor">Login</button>
					</div>
				</div>

				<div class="mt-3"></div>
			</form>
		</div>
		<p class="text-center mt-4 app-blue">
			<a class="font-italic pointer-cursor"
				[routerLink]="'../' + Path.FORGOT_PASSWORD"
				[queryParams]="{ username: getValidUsernameOrEmpty() }">Forgot
				Password</a>
		</p>
	</div>
</div>
