export class DashboardUserModel {
//  firstName: string;
//  lastName: string;
//  username: string;
//  lastLogin: Date;

constructor(
        public name: string,
        public email: string,
        public employer: string
      ) {  }
}
