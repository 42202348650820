<div class="toolbar" role="banner">
  <div class="spacer"></div>
</div>
<div class="content" role="main">
  <router-outlet></router-outlet>
</div>
<div class="landing-container">
  <div class="card card-body app-line"></div>
  <div class="container landing-cards">
    <div class="card content">
      <div class="card card-header app-line"></div>
      <div class="card-body login-card">
<!--         <app-message-alert [message]="message?.content"></app-message-alert> -->
        <div class="d-flex justify-content-center mb-4">
          <div class="col-5">
<!--             <app-logo [imageUrl]="'assets/employer_logo.svg'"></app-logo> -->
          </div>
          <div class="align-self-center">
          </div>
        </div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <div class="app-white mt-3 d-flex justify-content-center mx-auto copyright-div text-center">
    <span class="copyright">
      Copyright &copy;, Nsparc. All Rights Reserved
    </span>
  </div>
</div>
