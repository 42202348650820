<div class="d-flex align-items-center">
  <div class="d-flex align-items-center justify-content-end full-width">
    <div *ngIf="!isMobile" class="px-2">
      <div class="d-flex flex-row align-items-center" *ngIf="principal">
        <div>
          <h4 class="username font-weight-bold text-left">{{principal.name}}</h4>
          <h6 *ngIf="principal.email">Username: {{principal.email}}</h6>
          <h6 class="font-itali c text-left"><span class="login">Organization: {{principal.employer}}</span></h6>
        </div>
      </div>
    </div>
    <a [routerLink]="[logoutUrl]">
      <span class="fa-stack fa-2x-responsive">
        <i class="fa fa-circle fa-stack-2x"></i>
        <i class="fa fa-sign-out fa-stack-1x fa-inverse"></i>
      </span>
    </a>
  </div>
</div>