import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardBaseComponent } from './dashboard-base/dashboard-base.component';
import { Path } from '../../constants/Path';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MemberDetailComponent } from './member-detail/member-detail.component';
import {AuthGuard} from './../../guards/auth.guard';
import { CreateMessageComponent } from './create-message/create-message.component';
import { MessageToggleComponent } from './message-toggle/message-toggle.component';
import { MessagesComponent } from './messages/messages.component';
import { LeavePageGuard } from '@app/guards/leave-page.guard';

const routes: Routes = [
    {
        path: Path.EMPTY,
        component: DashboardBaseComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: Path.DASHBOARD,
                component: DashboardComponent,
                data: { title: 'Employer Dashboard' }
            },
            {
                path: Path.MEMBER_DETAIL,
                component: MemberDetailComponent,
                data: { title: 'Member Detail' }
            },
            {
                path: Path.MESSAGE_TOGGLE,
                component: MessageToggleComponent,
                data: { title: 'Messages' },
                children: [
                    {
                        path: Path.MESSAGES,
                        component: MessagesComponent,
                        data: { title: 'View Messages' }
                    } ,
                    {
                        path: Path.SEND_MESSAGES,
                        canDeactivate: [LeavePageGuard],
                        component: CreateMessageComponent,
                        data: { title: 'Send Messages' }
                    }
                ]
            },
            {
                path: Path.SEND_MESSAGES,
                component: CreateMessageComponent,
                data: { title: 'Send Messages' }
            }
        ]
    }
];

@NgModule( {
    imports: [RouterModule.forChild( routes )],
    exports: [RouterModule]
} )
export class DashboardRoutingModule {
}
