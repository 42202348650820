<div class="container">
	<div class="row">
		<div class="col-md-12">
			<app-loading *ngIf="loading" class="loading-container" [bigSize]="true"> </app-loading>
		</div>
	</div>
	<div class="row  justify-content-start mb-3">
		<!-- BEGIN back button and page title -->
		<div class="col-md-3 ">
			<button class="btn btn-outline-primary text-nowrap" [routerLink]="'/dashboard'">
				<fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
				Back to Dashboard
			</button>
		</div>
		<div class="col-md-2"></div>
		<div class="col-md-7 stats-main-number font-weight-bold">Talent Community Member</div>
	</div>
	<!-- END back button and page title -->
	<!-- BEGIN with Member name and send message button -->
	<div class="row justify-content-start mb-3 ">
		<div class="col-md-3  stats-main-number font-weight-bold">{{memberName | titlecase}}</div>
		<div class="col-md-6"></div>
		<div class="col-md-3 ">
		</div>
	</div>
	<!-- END ROW with Member name and send message button -->
	<!-- BEGIN MAIN content -->
	<div class="container card bg-light">
		<!--  		BEGIN STATIC VIEW Person info -->
		<div class="row m-2">
			<div class="col-md-12 card" *ngIf="!isEditEnabled">
				<div class="row justify-content-start m-1">
					<div class=" stats-main-number font-weight-bold col-md-5 pl-0">Personal Information</div>
					<div class="col-md-7 m-0 p-0">
						<button class="btn btn-outline-primary float-right" (click)="toggleEditMode()">
							<fa-icon [icon]="['fas', 'edit']"></fa-icon>
							Edit Member
						</button>
					</div>
				</div>
				<div class="row mb-3 ml-2">
					<div class="col-3 border-bottom">
						<div class="row font-weight-bold text-primary">Email</div>
						<div class="row text-break">{{member.email}}</div>
					</div>
					<div class="col-1"></div>
					<div class="col-3 border-bottom ">
						<div class="row font-weight-bold text-primary">Phone</div>
						<div class="row text-break">{{member.phone | phone}}</div>
					</div>
					<div class="col-1"></div>
					<div [ngClass]="{'col-3': countyOptions.length === 0, 'col-1': countyOptions.length > 0}" class="border-bottom ">
						<div class="row font-weight-bold text-primary">State</div>
						<div class="row">{{member.state}}</div>
					</div>
					<div *ngIf="countyOptions.length > 0" class="col-1"></div>
					<div *ngIf="countyOptions.length > 0" class="col-1 border-bottom">
						<div class="row font-weight-bold text-primary">County</div>
						<div class="row">{{countyString}}</div>
					</div>
				</div>
				<div class="row  mb-3 ml-2">
					<div class="col-3 border-bottom">
						<div class="row text-break font-weight-bold text-primary">Registration Date</div>
						<div class="row">{{member.registration_date| date: "MM/dd/yyyy"}}</div>
					</div>
					<div class="col-1"></div>
					<div class="col-3 border-bottom">
						<div class="row text-break font-weight-bold text-primary">SMS Notification</div>
						<div class="row">{{smsPreference}}</div>
					</div>
					<div class="col-1"></div>
					<div class="col-3 border-bottom">
						<div class="row font-weight-bold text-primary">Email Notification</div>
						<div class="row">{{emailPreference}}</div>
					</div>
				</div>
				<div class="row mb-2">
					<div class="col-md-12">
						<button class="small pull-right btn btn-outline-danger btn-sm" (click)="confirmDelete()"
							[promiseBtn]="requestInProgress">Remove Member</button>
					</div>
				</div>
			</div>
			<!-- 			END STATIC VIEW Person info -->
			<!-- 			BEGIN EDIT FORM Person info -->
			<div class="col-md-12 card" *ngIf="isEditEnabled">
				<div class="container mt-1">
					<div class="row mr-n1 ml-n4">
						<div class="col-md-12">
							<div *ngIf="isMemberTaken" class="alert alert-danger">This member already has this
								information. Please make a change and then save.</div>
							<div *ngIf="isSystemError" class="alert alert-danger">We are experiencing technical
								difficulties. Please wait a moment and try again.</div>
						</div>
					</div>
				</div>
				<form (ngSubmit)="onSubmit()" [formGroup]="memberForm">
					<div class="row justify-content-start m-1">
						<div class=" stats-main-number font-weight-bold col-md-5 pl-0">Personal Information</div>
						<div class="col-md-7 m-0 p-0">
							<div class="row">
								<div class="col-sm-12 pr-0">
									<button type="submit" *ngIf="isEditEnabled"
										[disabled]="memberForm.status === 'INVALID' || requestInProgress"
										[promiseBtn]="requestInProgress"
										class="btn btn-outline-primary text-break float-right">
										<fa-icon [icon]="['fas', 'edit']"></fa-icon>
										Save Changes
									</button>
								</div>
							</div>
							<div class="row pr-0">
								<div class="col-sm-12">
									<a class="text-danger small pull-right" (click)="cancelEdit()">Discard
										Changes</a>
								</div>
							</div>
						</div>
					</div>
					<div class="row form-group">
						<div class="col">
								<div class="font-weight-bold text-primary">First Name</div>
								<input type="text" id="firstName" class="form-control white-bg" required
									formControlName="firstName">
								<div [hidden]="memberForm.controls.firstName.status==='VALID' || memberForm.controls.firstName.pristine"
									class="col alert alert-danger mb-0">
									<div *ngIf="memberForm.controls.firstName.errors?.required">First name is
										required
									</div>
									<div *ngIf="memberForm.controls.firstName.errors?.maxlength">First Name must not
										be longer than 50 characters.</div>
								</div>
						</div>
						<div class="col">
							<div class="font-weight-bold text-primary">Last Name</div>
							<input type="text" id="firstName" class="form-control white-bg" required
								formControlName="lastName">
							<div [hidden]="memberForm.controls.lastName.status==='VALID' || memberForm.controls.lastName.pristine"
								class="col alert alert-danger mb-0">
								<div *ngIf="memberForm.controls.lastName.errors?.required">Last name is required
								</div>
								<div *ngIf="memberForm.controls.lastName.errors?.maxlength">Last Name must not
									be longer than 50 characters.</div>
							</div>
						</div>
					</div>
					<div class="row form-group">
						<div class="col">
							<div class="font-weight-bold text-primary">Email</div>
							<input type="email" class="form-control white-bg" id="email" required
								formControlName="email">
							<div [hidden]="memberForm.controls.email.status==='VALID' || memberForm.controls.email.pristine"
								class="col alert alert-danger mb-0">
								<div *ngIf="memberForm.controls.email.errors?.required">Email is required.</div>
								<div *ngIf="memberForm.controls.email.errors?.maxlength">Email must not be
									longer than 256 characters</div>
								<div *ngIf="memberForm.controls.email.errors?.pattern">The email address entered
									does not meet formatting requirements.</div>
								<div *ngIf="memberForm.controls.email.errors?.taken">The email you entered is
									already registered.</div>
							</div>
						</div>
						<div class="col">
							<div class="font-weight-bold text-primary">Phone</div>
							<input type="tel" class="form-control white-bg" id="phone" required
								formControlName="phone" [specialCharacters]="['+', '1', '\(', '\)', '-']" mask="(000)000-0000">
							<div [hidden]="memberForm.controls.phone.status==='VALID' || memberForm.controls.phone.pristine"
								class="col alert alert-danger mb-0">
								<div *ngIf="memberForm.controls.phone.errors?.required">Mobile Phone # is
									required.
								</div>
								<div *ngIf="memberForm.controls.phone.errors?.mask">Phone number must be ten
									digits and use the following format: (XXX)XXX-XXXX.</div>
								<div *ngIf="memberForm.controls.phone.errors?.taken">The phone number you
									entered is already registered.</div>
								<div *ngIf="memberForm.controls.phone.errors?.invalid">The phone number you entered is invalid.</div>
							</div>
						</div>
					</div>
					<div class="row form-group">
						<div [ngClass]="{'col-md-12': countyOptions.length === 0, 'col-md-6': countyOptions.length > 0}" class="pl-0">
							<div class="container pr-0">
								<div class="row m-0 font-weight-bold text-primary">
									<div class="col p-0">State</div>
								</div>
								<div class="row m-0">
									<div class="col p-0">
										<ng-select #ngSelect [items]="stateOptions" formControlName="state"
											[clearable]="true" [searchable]="true" bindValue="id" bindLabel="name">
										</ng-select>
										<div [hidden]="memberForm.controls.state.status==='VALID' || memberForm.controls.state.pristine"
											class="alert alert-danger mb-0">
											<div *ngIf="memberForm.controls.state.errors?.required">State is
												required.
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="countyOptions.length > 0" class="col-md-6 pl-0">
							<div class="container">
								<div class="row m-0 font-weight-bold text-primary">
									<div class="col p-0">County</div>
								</div>
								<div class="row m-0">
									<div class="col p-0">
										<ng-select [items]="countyOptions" formControlName="county"
										[clearable]="false" [searchable]="true" bindValue="fipsCode" bindLabel="description"></ng-select>
									</div>
									<div [hidden]="memberForm.controls.county.status==='VALID' || memberForm.controls.county.pristine"
											class="alert alert-danger mb-0">
											<div *ngIf="memberForm.controls.county.errors?.required">County is
												required.
											</div>
										</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row form-group mb-3 ml-0">
						<div class="col-md-5 border-bottom">
							<div class="row text-break font-weight-bold text-primary pl-0">
								<div class="col-md-12 pl-0">
									Registration Date
								</div>
							</div>
							<div class="row">
								<div class="col-md-12 pl-0">
									{{member.registration_date| date: "MM/dd/yyyy"}}
								</div>
							</div>
						</div>
						<div class="col-2"></div>
						<div class="col-md-5 ml-0">
							<div class="row text-break font-weight-bold text-primary">
								<div class="col-md-12 pl-0">Notification Preferences</div>
							</div>
							<div class="row">
								<div class="col-md-12 pl-0 white-bg">
									<ng-select #ngSelect bindValue="code" bindLabel="value" [items]="preferenceOptions"
										formControlName="preference" [searchable]="true" class="white-bg"></ng-select>
									<div [hidden]="memberForm.controls.preference.status==='VALID' || memberForm.controls.preference.pristine"
										class="alert alert-danger mb-0">
										<div *ngIf="memberForm.controls.preference.errors?.required">Notification
											Preference is required.</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
			<!-- 			END EDIT-FORM Person info -->
		</div>
		<!-- END Personal info -->
		<!-- BEGIN Communication and history section -->
		<div class="card row m-2">
			<div class="container">
				<div class="row mt-3 mb-3 ">
					<div class="stats-main-number font-weight-bold col ">Communication History</div>
					<div class="col">
						<a class="float-right">See All</a>
					</div>
				</div>
				<div class="row">
					<div class=" table-card mt-3 w-100">
						<div class="table-responsive ">
							<ng-container>
								<ng-container>
									<cdk-virtual-scroll-viewport itemSize="10"
										(scrolledIndexChange)="scrollHandler($event,(messages.Items[messages.Items.length-1]))">
										<table class="table">
											<div class="d-flex p-2 border-bottom h6 text-left">
												<div class=" d-flex flex-row w-100 font-weight-bold">
													<div class="p-2 text-primary date-col">Date</div>
													<div class="p-2 text-primary type-col">Type</div>
													<div class="p-2 text-primary message-col">Message</div>
												</div>
											</div>
											<tbody *ngIf="messages.Items.length < 1">
												<div class="text-secondary overflow-hidden text-center">
													No messages have been sent to this member.
												</div>
											</tbody>
										</table>
										<li *cdkVirtualFor="let message of messages.Items;"
											class="list-unstyled border-bottom  ">
											<div class="d-flex p-2 text-nowrap ">
												<div class=" d-flex flex-row w-100  text-secondary  text-left ">
													<div class="p-2 date-col ">
														{{message.date| date: "MM/dd/yyyy"}}
													</div>
													<div class="p-2 text-secondary type-col">
														{{message.type}}
													</div>
													<div class="p-2 text-secondary message-col text-wrap">
														{{message.body}}</div>
												</div>
											</div>
										</li>
										<div *ngIf="searchingMessages">
											<button class="btn btn-link btn-reset"
												[promiseBtn]="searchingMessages">Loading...</button>
										</div>
									</cdk-virtual-scroll-viewport>
								</ng-container>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- END Communication and history section -->
	</div>
	<!-- END MAIN content -->
</div>