import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingComponent } from '@app/shared/loading/loading.component';
import { RouterModule } from '@angular/router';
import { ConfirmDialogComponent } from '@app/shared/confirm-dialog/confirm-dialog.component';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { PhonePipe } from './pipes/phone.pipe';
import { CustomDateFormatterParserModule } from './custom-date-formatter-parser/custom-date-formatter-parser.module';

@NgModule({
    declarations: [
        LoadingComponent,
        ConfirmDialogComponent,
        PhonePipe
    ],
    imports: [
        CommonModule,
        NgbModule,
        Angular2PromiseButtonModule.forRoot({ disableBtn: false }),
        RouterModule,
        CustomDateFormatterParserModule
    ],
    entryComponents: [],
    exports: [
        LoadingComponent,
        ConfirmDialogComponent,
        Angular2PromiseButtonModule,
        PhonePipe,
        CustomDateFormatterParserModule
    ]
})
export class SharedModule {
}
