import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  title: string;

  constructor(private browserTitle: Title) {
  }

  setTitle(title: string) {
    this.title = title;
  }

  getTitle(): string {
    return (this.title || '').replace(/<[^>]*>/g, '');
  }

  getHtmlTitle(): string {
    return this.title;
  }

  setBrowserAndPageTitle(title: string) {
    this.setTitle(title);
    this.browserTitle.setTitle(title ? `${this.getTitle()} | Employer` : 'Employer');
  }
}
