import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '@env/environment';
import { Endpoint } from '@app/constants/Endpoint';
import { NotificationSearchModel } from '@app/models/notification-search.model';
import { NotificationSearchType } from '@app/constants/NotificationSearchType';
import { NotificationRequestModel } from '@app/models/notification-request.model';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(private http: HttpClient) {
    }

    requestNotification(model: NotificationRequestModel): Observable<any> {
        return this.http.post<any>(environment.host + `${Endpoint.NOTIFICATION}${Endpoint.REQUEST}`, model);
    }

    searchBulkNotifications(model: NotificationSearchModel): Observable<any> {
        return this.http.post<any>(environment.host + `${Endpoint.NOTIFICATION}${Endpoint.SEARCH}`, model);
    }

    searchMemberNotifications(model: NotificationSearchModel): Observable<any> {
        model.searchType = NotificationSearchType.MEMBER;
        return this.http.post<any>(environment.host + `${Endpoint.NOTIFICATION}${Endpoint.SEARCH}`, model);
    }
}
