import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { Endpoint } from '@app/constants/Endpoint';
import { environment } from '@env/environment';
import { catchError } from 'rxjs/operators';
import { MessageType } from '@app/constants/MessageType';

@Injectable({
    providedIn: 'root'
})
export class MemberService {

    private membersToMessageSource = new BehaviorSubject("");
    membersToMessage = this.membersToMessageSource.asObservable();
    private messageTypeSource = new BehaviorSubject(null);
    messageType: Observable<MessageType> = this.messageTypeSource.asObservable();

    constructor(private http: HttpClient) { }

    searchMember(model: any): Observable<any> {
        //        return of(model);
        return this.http.post(environment.host + `${Endpoint.MEMBER}` + `${Endpoint.SEARCH}`, model);
    }


    editMember(member: any): Observable<any> {
        return this.http.put(environment.host + `${Endpoint.MEMBER}`, member).pipe(
            catchError(error => {
                return of(error);
            }
            )
        );
        // replace above line with below commented line in order to mock the call;
        //        return of( { "error": { "message": "The member is already taken" } } );
        //        return of( {} ); 
    }

    deleteMembers(members): Observable<any> {
        let body = { "members": this.createDeleteBody(members) };
        return this.http.request('delete', environment.host + `${Endpoint.MEMBER}`, { "body": body });
        // replace above line with below commented line in order to mock the call;
        //        return of({});
    }

    createDeleteBody(members) {
        let formatted = [];
        for (let i = 0; i < members.length; i++) {
            let mem = {};
            mem["id"] = members[i].id;
            mem["skey"] = members[i].skey;
            mem["email"] = members[i].email;
            mem["phone"] = members[i].phone;
            mem["unsub_email"] = members[i].unsub_email;
            mem["unsub_phone"] = members[i].unsub_phone;

            formatted.push(mem);
        }
        return formatted;
    }

    changeMembersToMessage(members: any) {
        this.membersToMessageSource.next(members);
    }

    changeMessageType(messageType: MessageType) {
        this.messageTypeSource.next(messageType);
    }
}
