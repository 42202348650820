import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '@env/environment';
import { Endpoint } from '@app/constants/Endpoint';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    constructor(private http: HttpClient) {
    }

    dashboardSearch(model: any): Observable<any> {
        return this.http.post<any>(environment.host + `${Endpoint.MEMBER}${Endpoint.SEARCH}`, model);
    }

    employerMetrics() {
        return this.http.get<any>(environment.host + `${Endpoint.METRICS}${Endpoint.RETRIEVE_METRICS}`);
    }
}

