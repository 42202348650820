import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '@app/services/notification.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AppCookieService } from '@app/services/app-cookie.service';

import { NotificationSearchModel } from '@app/models/notification-search.model';
import { NotificationSearchType } from '@app/constants/NotificationSearchType';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageType } from '@app/constants/MessageType';
import { MessageStatus } from '@app/constants/MessageStatus';
import { TitleCasePipe } from '@angular/common';
import { SortDirection } from '@app/constants/SortDirection';

@Component({
    selector: 'app-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit, OnDestroy {

    @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;

    messageType = MessageType;
    messageStatus = MessageStatus;

    theEnd = false;

    messages = {
        "Items": [],
        "Count": null,
        "ScannedCount": null
    };
    loading = true;
    searching = true;
    LastEvaluatedKey = null;
    lastEvalArr = [];
    collectionSize: number;
    page = 0;
    defaultItemsPerPage = 10;
    expandedMessage = null;
    searchModel = new NotificationSearchModel();
    messageTypeItems: {key: string, value: string}[] = [];
    messageStatusItems: {key: string, value: string}[] = [];
    messageSearchForm = this.fb.group({
        date: [],
        messageType: ['E', [Validators.required]],
        messageStatus: [null, [Validators.required]]
    });
    fc = this.messageSearchForm.controls;

    constructor(private service: NotificationService, private router: Router, private fb: FormBuilder, private cookie: AppCookieService,
        private titleCasePipe: TitleCasePipe) { }

    ngOnInit(): void {
        this.loadMessageTypes();
        this.loadMessageStatus();
        this.loadMessages();
    }

    loadMessageTypes() {
        Object.keys(MessageType).forEach(t => this.messageTypeItems.push({key: MessageType[t], value: t}));
        this.messageTypeItems.sort((i1, i2) => i1.key.localeCompare(i2.key));
    }

    loadMessageStatus() {
        this.messageStatusItems.push({key: 'All', value: null});
        Object.keys(MessageStatus).filter(s => s != MessageStatus.PENDING)
        .forEach(s => this.messageStatusItems.push({key: this.titleCasePipe.transform(MessageStatus[s]), value: s}));
    }

    loadMessages() {
        const search = this.getDefaultSearchModel();
        search.searchType = NotificationSearchType.BULK;
        this.searchMessages(search);
        this.collectionSize = this.messages.Items.length;
    }

    getDefaultSearchModel() {
        const model = new NotificationSearchModel();
        model.direction = SortDirection.DESC;
        model.itemsPerPage = 10;
        model.messageType = MessageType.E;
        return model;
    }

    formatDate(dateStr) {
        let date = dateStr.split('T')[0];
        let parts = date.split('-');
        const separator = "/";
        return parts[1] + separator + parts[2] + separator + parts[0];
    }

    getMessageMode(skey) {
        let method = skey.split('#')[2];
        if (method === "E") {
            return "Email";
        } else {
            return "SMS";
        }
    }

    ngOnDestroy(): void {
    }

    expand(id: number) {
        if (id === this.expandedMessage)
            id = null;

        for (let m = 0; m < this.messages.Items.length; m++) {
            if (this.messages.Items[m].id === id || this.messages.Items[m].id === this.expandedMessage)
                this.messages.Items[m].expanded = !this.messages.Items[m].expanded;

        }

        this.expandedMessage = id;
    }

    getType(type: String) {
        return type === 'E' ? "Email" : "SMS";
    }

    getCurrentSearchModel(): NotificationSearchModel {
        const date = this.fc['date'].value;
        const messageType = MessageType[this.fc['messageType'].value];
        const messageStatus = MessageStatus[this.fc['messageStatus'].value];
        
        const model = new NotificationSearchModel();
        model.direction = SortDirection.DESC;
        model.itemsPerPage = this.defaultItemsPerPage;
        model.onAfterDate = date;
        model.messageType = messageType;
        model.messageStatus = messageStatus;
        model.ExclusiveStartKey = this.LastEvaluatedKey;
        model.searchType = NotificationSearchType.BULK;
        return model;
    }

    clearMessages() {
        this.messages.Count = null;
        this.messages.Items = [];
        this.messages.ScannedCount = null;
    }

    onSearch() {
        this.searchModel = this.getCurrentSearchModel();
        this.searchMessages(this.searchModel);
    }

    onSearchClick() {
        this.reinitializeVariables();
        this.clearMessages();
        this.onSearch();
    }

    onResetClick() {
        this.messageSearchForm.reset();
        this.clearMessages();
        this.reinitializeVariables();
        this.fc['messageType'].setValue('E');
        this.onSearch();
    }

    reinitializeVariables() {
        this.page = 0;
        this.collectionSize = 0;
        this.lastEvalArr = [];
        this.LastEvaluatedKey = null;
    }

    private searchMessages(search: NotificationSearchModel) {
        this.searching = true;
        this.loading = true;
        this.service.searchBulkNotifications(search).subscribe(response => {
            response.Items.forEach(message => {
                this.messages.Items = [...this.messages.Items, message];
            });
            this.page = this.page++;
            if (response.LastEvaluatedKey && this.keyNotAlreadyContained(response.LastEvaluatedKey)) {
                this.lastEvalArr.push(response.LastEvaluatedKey);
                this.searchModel.ExclusiveStartKey = response.LastEvaluatedKey;
                this.LastEvaluatedKey = response.LastEvaluatedKey;
            }
            this.theEnd = response.LastEvaluatedKey === undefined;
            this.loading = false;
            this.searching = false;
        });
    }

    keyNotAlreadyContained(key) {
        for (let i = 0; i < this.lastEvalArr.length; i++) {
            if (key.id === this.lastEvalArr[i].id && key.skey === this.lastEvalArr[i].skey)
                return false;
        }
        return true;
    }

    scrollHandler(event, message) {
        if (message === undefined || this.theEnd)
            return;

        const key = {
            id: message.id,
            skey: message.skey
        };

        const end = this.viewport.getRenderedRange().end;
        const total = this.viewport.getDataLength();
        if (end === total && !this.theEnd && !this.searching) {
            this.onSearch();
        }
    }
}
