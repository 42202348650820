export const DATE_FORMAT = 'M/d/yyyy';

export const DATE_TIME_FORMAT = 'M/d/yyyy, h:mm a';

export const ADDRESS_VALIDATION_MESSAGE = ' may only contain alphanumeric characters, pound sign (#), or forward slash (/).';

export const LETTERS_VALIDATION_MESSAGE = 'City may only contain letters, spaces, dashes, apostrophes, and periods.';

export const CHECKBOX_SELECTIVE_SERVICE_TEXT = `I affirm under penalty of perjury (as defined by
  <a href="http://www.lexisnexis.com/hottopics/michie/" target="_blank">Miss. Code Ann. Section 97-9-59</a>)
   that, if required by law, I am registered with Selective Service.`;

export const APP_FROM_EMAIL = 'msfinancialaid@mississippi.edu';

export const EMAIL_CHECKBOX_TEXT = `I understand that by providing an email address,
 the MS Office of Student Financial Aid will communicate important information via the Primary Email Address.
 I have provided an email address that I check regularly.
 Please save the following address to your safe senders list to ensure receipt of important correspondence
 regarding your application for state aid: <a href="mailto:${APP_FROM_EMAIL}">${APP_FROM_EMAIL}</a>.`;

export const MESSAGE_ONLY_LITERAL = 'Middle Initial must contain letters.';

export const OTHER_COLLEGE_INVALID_TEXT = 'High School name must contain only letters, numbers, blanks and dashes';

export const OTHER_HIGH_SCHOOL_INVALID_TEXT = 'High School name must contain only letters, numbers, blanks and dashes';

export const OTHER_HIGH_SCHOOL_TEXT = 'Other';

export const OTHER_COLLEGE_TEXT = 'Other College';

export const C2C_APPLICANTS_ONLY = '(Complete 2 Compete (C2C) Applicants Only)';

export const OTHER_CLASSIFICATION_TEXT = 'Other Classification';

export const OTHER_MAJOR_TEXT = 'Other';

export const OTHER_HIGH_SCHOOL_VALUE = '800000';
export const STAR_TOOLTIP = 'Please complete the Free Application for Federal Student Aid at' +
  '  <a href="www.fafsa.ed.gov" target="_blank">www.fafsa.ed.gov</a>.  ' +
  'If you have already submitted the FAFSA, please allow at least 1-2 weeks for our office to receive your application.' +
  '  A printed copy of the completed FAFSA will not be accepted.';

export const FORM_INCORRECT_MESSAGE = 'The form is incorrect. Please correct it.';

export const TRCO_TOOLTIP = 'Ask the financial aid office at the institution in which you are enrolling' +
  ' to submit your grade verification electronically to our office.  Do not send a transcript.';
