import { Injectable } from '@angular/core';

type Tracker = {
  send: (
    hitType: string,
    category: string,
    action: string,
    label?: string
  ) => void;
};

declare const gtag: {
  (...args: any[]): () => void;
  getAll: () => Tracker[];
};

const has = Object.prototype.hasOwnProperty;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }

  logEvent(
    pageName: string,
    action: string,
    buttonName?: string) {
    console.log("logEvent for " + pageName + " : " + action + " : " + buttonName);
    gtag('event', pageName, { 'action': action, 'button': buttonName });
  }

  logPageView(url: string) {
    console.log("logPageView for " + url);
    gtag(() => {
      if (has.call(window, "gtag")) {
        gtag("set", "page", url);
        gtag("send", "pageview");
      }
    });
  }
}
