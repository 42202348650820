import { HttpHandler, HttpInterceptor, HttpRequest, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core'
import { Router } from '@angular/router';
import { AppCookieService } from '@app/services/app-cookie.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()

export class HeaderInterceptor implements HttpInterceptor {
    constructor(private cookie: AppCookieService, private router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.headers.has('authorization') && request.headers.has('employer')) {
            return next.handle(request);
        }

        if (this.cookie.getCookie('token') && this.cookie.getCookie('tokenExp')) {
            if (Math.floor(Date.now()/1000) > parseInt(this.cookie.getCookie('tokenExp'))) {
                this.router.navigateByUrl('/logout');
            }
            let idTokenString = this.cookie.getCookie('token');
            request = this.addIdToken(request, idTokenString);
        }

        if (this.cookie.getCookie("employer")) {
            let backendEmployerString = this.cookie.getCookie('employer').split(' ').join('_');
            request = this.addEmployerHeader(request, backendEmployerString);
        }

        return next.handle(request).pipe(catchError(error => {
            console.log('There was a problem with the request');
            throw error;
        }));
    }

    private addIdToken(request: HttpRequest<any>, idToken: string) {
        return request.clone({setHeaders: {authorization: idToken}});
    }

    private addEmployerHeader(request: HttpRequest<any>, employer: string) {
        return request.clone({setHeaders: {employer: employer}});
    }
}