<div *ngIf="!messagePending && !searching" class="container mt-2 card bg-light">
	<form (ngSubmit)="onSubmit()" [formGroup]="messageForm">
		<div class="row mt-3">
			<div class="col-md-12 ">
				<div class="font-weight-bold">Type</div>
			</div>
		</div>
		<div class="row">
			<div class="col-xs-auto pl-3 mb-2 mt-1">
				<label class="float-left btn btn-primary" [ngClass]="{'active':messageType===msgTypeEnum.E}"> <input type="radio"
						name="messageType" id="Email" formControlName="messageType" value="{{msgTypeEnum.E}}"
						(click)="confirmChangeMessageType(msgTypeEnum.E)" [checked]="messageType === msgTypeEnum.E"> Email
				</label>
			</div>
			<div class="col-xs-auto mb-2 pl-1 mt-1">
				<label class="btn btn-primary" [ngClass]="{'active':messageType===msgTypeEnum.S}"> <input type="radio"
						name="messageType" id="SMS" formControlName="messageType" value="{{msgTypeEnum.S}}"
						(click)="confirmChangeMessageType(msgTypeEnum.S)" [checked]="messageType === msgTypeEnum.S"> SMS
				</label>
			</div>
		</div>
		<ng-container *ngIf="messageType">
			<div class="row">
				<div class="col-md-12">
					<div class="font-weight-bold">Message</div>
				</div>
			</div>
			<div class="row" *ngIf="messageType===msgTypeEnum.E">
				<div class="col-md-12 mb-1">
					<input class="form-control bg-white" id="search" placeholder="Subject"
						formControlName="messageSubject">
					<div [hidden]="messageForm.controls.messageSubject.status==='VALID' || messageForm.controls.messageSubject.pristine"
						class="alert alert-danger mb-0">
						<div *ngIf="messageForm.controls.messageSubject.errors?.required">Subject must contain at least
							one character</div>
						<div *ngIf="messageForm.controls.messageSubject.errors?.maxlength">Subject may only contain
							{{this.subjectMaxLength}} character.</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12 mb-2">
					<div *ngIf="messageType === msgTypeEnum.S">
						<textarea formControlName="messageBody" placeholder="Body" class="form-control bg-white"
							id="search"></textarea>
						<div [hidden]="messageForm.controls.messageBody.status==='VALID' || messageForm.controls.messageBody.pristine"
							class="alert alert-danger mb-0">
							<div *ngIf="messageForm.controls.messageBody.errors?.required">SMS messages must contain at
								least one character</div>
							<div *ngIf="messageForm.controls.messageBody.errors?.maxlength">SMS messages may only
								contain {{smsMaxLength}} character</div>
						</div>
					</div>
					<div *ngIf="messageType === msgTypeEnum.E" id="quil-editor">
						<quill-editor formControlName="messageBody" format="html" maxLength="emailMaxLength"
							[modules]="mod"></quill-editor>
						<div [hidden]="messageForm.controls.messageBody.status==='VALID' || messageForm.controls.messageBody.pristine"
							class="alert alert-danger mb-0">
							<div *ngIf="messageForm.controls.messageBody.errors?.maxlength">Email messages may only
								contain {{emailMaxLength}} characters</div>
							<div *ngIf="messageForm.controls.messageBody.errors?.required">Email messages must contain
								at least one character</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row mb-5" *ngIf="messageType === msgTypeEnum.S">
				<div class="col-md-12">
					<div class="float-right small">{{messageForm.controls.messageBody.value === null ? 0 :
						messageForm.controls.messageBody.value.length}}/ {{messageType === msgTypeEnum.S ? this.smsMaxLength
						:this.emailMaxLength }} characters</div>
				</div>
			</div>
			<div class="row mb-5">
				<div class="col-md-12">
					<button [disabled]="messageForm.status === 'INVALID'" [promiseBtn]="sending"
						class="btn btn-outline-primary float-right" type="submit">{{sendForApprovalText}}</button>
				</div>
			</div>
		</ng-container>
	</form>
</div>
<div class="row" *ngIf="messagePending && !searching">
	<div class="col-lg-3"></div>
	<div class="my-3 col-lg-6 col-12 font-weight-bold text-center">
		You currently have a message that is pending MDES approval. You can send a new message for approval once it is approved or rejected.
	</div>
	<div class="col-lg-3"></div>
	<mat-expansion-panel [expanded]="true" class="my-3 col-12">
		<mat-expansion-panel-header class="my-1">
			<mat-panel-title *ngIf="message.send_mode">
				<div class="col-2 font-weight-bold text-primary">
					{{message.request_date | date: 'yyyy-MM-dd'}}
					<br/>
					<span class="font-weight-normal text-dark">{{msgTypeEnum[message.send_mode]}}</span>
				</div>
				<div class="col-2 font-weight-bold text-primary">
					Status
					<br/>
					<span class="font-weight-normal" [ngClass]="{'text-success': message.status === messageStatus.APPROVED,
					'text-danger': message.status === messageStatus.REJECTED, 'text-secondary': message.status === messageStatus.PENDING}">
						{{message.status | titlecase}}</span>
				</div>
				<!-- <div class="col-2 font-weight-bold text-primary">
					<span *ngIf="!!message.sent_date">
						Sent On:
						<br/>
						<span class="font-weight-normal text-dark">{{message.sent_date | date: 'yyyy-MM-dd'}}</span>
					</span>
				</div> -->
				<div class="col-2 font-weight-bold text-primary">
					Members
					<br/>
					<span class="font-weight-normal text-dark">{{message.count}}</span>
				</div>
				<div class="col-4 font-weight-bold text-primary">
					<span *ngIf="!!message.subject">
						Subject
						<br/>
						<span
						class="d-inline-block font-weight-normal text-dark text-hidden-overflow-long">
							{{message.subject}}
						</span>
					</span>
				</div>
				<div class="col-2"></div>
			</mat-panel-title>
		</mat-expansion-panel-header>
			<div *ngIf="!!message.subject" class="col-12 font-weight-bold text-primary">
				Subject:
			</div>
			<div *ngIf="!!message.subject" class="col-12 card-body border ml-3">
				{{message.subject}}
			</div>
			<div class="col-12 font-weight-bold text-primary">
				Body:
			</div>
			<div class="col-12 card-body border ml-3">
				<span [innerHTML]="message.content"></span>
			</div>
			<div *ngIf="!!message.reason" class="col-12 font-weight-bold text-primary">
				Reason:
			</div>
			<div *ngIf="!!message.reason" class="col-12 card-body border ml-3">
				{{message.reason}}
				</div>
	</mat-expansion-panel>
</div>
<app-loading [bigSize]="true" *ngIf="searching">
</app-loading>
