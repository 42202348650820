import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ConfirmDialogComponent } from '@app/shared/confirm-dialog/confirm-dialog.component';
import { CanDeactivateComponent } from '@app/shared/interfaces/can-deactivate.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LeavePageGuard implements CanDeactivate<CanDeactivateComponent> {
  constructor(private modalService: NgbModal) {}

  canDeactivate(component: CanDeactivateComponent): boolean | Observable<boolean> | Promise<boolean> {
    // return component.canDeactivate ? component.canDeactivate() : true;
    if (component.canDeactivate()) {
      return true;
    } else {
      const confirmDialog = this.modalService.open(ConfirmDialogComponent, { centered: true });
      confirmDialog.componentInstance.pageTitle = "";
      confirmDialog.componentInstance.pageBody = "Are you sure you want to leave this page? There are changes on this page that have not been saved. If you continue, the changes will be lost.";
      return confirmDialog.componentInstance.confirm$.asObservable();
    }
  }
}
