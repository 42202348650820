import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  transform(value: string): string {
    try {
      let country = "";
      let city = "";
      let num = "";

      value = value.replace(/[^0-9.]/g, "");
      switch (value.length) {
        case 7:
        case 10:
          country = "1";
          city = value.slice(0, 3);
          num = value.slice(3);
          break;
        case 11:
          country = value[0];
          city = value.slice(1, 4);
          num = value.slice(4);
          break;
        case 12:
          country = value.slice(0, 3);
          city = value.slice(3, 5);
          num = value.slice(5);
          break;
        default:
          return value;
      }

      if (country == "1") {
        country = "";
      }
      num = num.slice(0, 3) + '-' + num.slice(3);
      return (country + " (" + city + ") " + num).trim();
    } catch (error) {
      return value;
    }
  }

}
