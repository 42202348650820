import { Component, OnInit } from '@angular/core';
import { TitleService } from '@app/services/title.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AppCookieService } from '@app/services/app-cookie.service';
import { DashboardUserModel } from '../dashboard-user.model';


@Component({
  selector: 'app-dashboard-base',
  templateUrl: './dashboard-base.component.html',
  styleUrls: ['./dashboard-base.component.scss']
})
export class DashboardBaseComponent implements OnInit {
    
   isMobile: boolean;
   principal: DashboardUserModel;


  constructor(private breakpointObserver: BreakpointObserver, public titleService: TitleService, private cookie: AppCookieService) { }

  ngOnInit(): void {
      this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
      .subscribe(state => this.isMobile = state.matches);
      this.principal=new  DashboardUserModel (this.cookie.getCookie( 'name' ), this.cookie.getCookie( 'email' ),this.cookie.getCookie( 'employer' ));
  }
}