import { NgModule } from '@angular/core';
import { AnonymousGuard } from "./anonymous.guard";
import { AuthGuard } from "./auth.guard";

@NgModule({
  declarations: [],
  imports: [    
  ],
  providers: [
    AnonymousGuard,
    AuthGuard
  ]
})
export class GuardsModule { }
