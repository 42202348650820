<div class="container">
	<div class="row justify-content-center">
		<div>
			<div class="pb-2 mt-4 mb-2 text-center">
				<h4 class="app-blue font-weight-bold">Change Password</h4>
				<div class="row">
					<div class="col">
						<div [hidden]="!requestError" class="alert alert-danger">
							<div *ngIf="isCodeExpired">
								This password recovery code was requested more than 24 hours ago. Please click
								<button class="btn btn-sm" (click)="resendCode()" [promiseBtn]="resendRequestInProgress">here</button>
								to resend a new recovery code.
							</div>
							<div *ngIf="isCodeInvalid">
								This password recovery code is invalid. Please click <a (click)="resendCode()">here</a> to resend a new recovery code.
							</div>
							<div *ngIf="isUserNotFound">Please confirm the below information is correct.</div>
							<div *ngIf="isLimitExceeded">Too many attempts have been made. Please wait a while before retrying.</div>
						</div>
					</div>
				</div>
				<div class="app-blue font-weight-bold text-left">Please enter the recovery code you received in your email as well as your new desired password.</div>
			</div>
		</div>
	</div>
	<div class="row justify-content-center" [hidden]="!isLinkExired">
		<div>
			<div class="col">
				<div class="font-weight-bold">Your new password must be:</div>
				<ul>
					<li>Between 8 and 30 characters</li>
					<li>Contain at least one number</li>
					<li>Contain at least one uppercase letter</li>
					<li>Contain at least one lowercase letter</li>
					<li>Contain at least one special symbol</li>
					<li>Have no spaces</li>
				</ul>
			</div>
		</div>
	</div>
	<div class="row justify-content-center" [hidden]="!isLinkExired">
		<div class="col">
			<div class="container">
				<form [formGroup]="resetPasswordForm" (submit)="submit()" class="m-auto col-sm-8">
					<div class="row mb-2">
						<div class="col" *ngIf="!usernameProvided">
							<div class="input-group-append">
								<input placeholder="Username" [type]="'text'" formControlName="username" class="form-control" id="username">
							</div>
							<div [hidden]="resetPasswordForm.controls.username.status==='VALID' || resetPasswordForm.controls.username.pristine" class="alert alert-danger col mb-0">
								<div *ngIf="resetPasswordForm.controls.username.errors?.required">Username is required</div>
							</div>
						</div>
						<div class="col" *ngIf="usernameProvided">
							<div class="input-group-append font-weight-bold">{{username}}</div>
						</div>
					</div>
					<div class="row mb-2">
						<div class="col">
							<div class="input-group-append">
								<input placeholder="Recovery Code" [type]="'text'" formControlName="code" class="form-control" id="code">
							</div>
							<div [hidden]="resetPasswordForm.controls.code.status==='VALID' || resetPasswordForm.controls.code.pristine" class="alert alert-danger col mb-0">
								<div *ngIf="resetPasswordForm.controls.code.errors?.required">Recovery code is required</div>
							</div>
						</div>
					</div>
					<div class="row mb-2">
						<div class="col">
							<div class="input-group-append">
								<input placeholder="New Password" [type]="showPassword ? 'text' : 'password'" formControlName="newPassword" class="form-control" id="newPassword" (keyup)="updateConfirmPasswordValidation()">
								<div class="input-group-append" (click)="toggleShowPassword()">
									<span class="input-group-text"> <i class="fa" [ngClass]="showPassword?'fa-eye':'fa-eye-slash'"></i>
									</span>
								</div>
							</div>
							<div [hidden]="resetPasswordForm.controls.newPassword.status==='VALID' || resetPasswordForm.controls.newPassword.pristine" class="alert alert-danger col mb-0">
								<div *ngIf="resetPasswordForm.controls.newPassword.errors?.required">Password is required</div>
								<div
									*ngIf="resetPasswordForm.controls.newPassword.errors?.pattern || 
								resetPasswordForm.controls.newPassword.errors?.maxlength || 
								resetPasswordForm.controls.newPassword.errors?.minlength || 
								resetPasswordForm.controls.newPassword.errors?.hasNotSpace">
									Please check that your password meets the above requirements.</div>
							</div>
						</div>
					</div>
					<div class="row mb-2">
						<div class="col">
							<div class="input-group-append">
								<input placeholder="Confirm New Password" [type]="showConfirmPassword ? 'text' : 'password'" formControlName="confirmPassword" class="form-control" id="confirmPassword"
									(keyup)="updateConfirmPasswordValidation()">
								<div class="input-group-append" (click)="toggleShowConfirmPassword()">
									<span class="input-group-text"> <i class="fa" [ngClass]="showConfirmPassword?'fa-eye':'fa-eye-slash'"></i>
									</span>
								</div>
							</div>
							<div [hidden]="resetPasswordForm.controls.confirmPassword.status==='VALID' || resetPasswordForm.controls.confirmPassword.pristine" class="alert alert-danger col mb-0">
								<div *ngIf="resetPasswordForm.controls.confirmPassword.errors?.required">Please re-enter your password.</div>
								<div *ngIf="resetPasswordForm.controls.confirmPassword.errors?.noMatch">Please ensure the fields match.</div>
							</div>
						</div>
					</div>
					<div class="row justify-content-md-center mt-2">
						<button class="btn btn-primary" type="submit" [disabled]="resetPasswordForm.status === 'INVALID'" [promiseBtn]="requestInProgress">Change password</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>