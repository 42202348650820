import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { StateService } from '@app/services/state.service';
import * as leaflet from 'leaflet';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-heatmap',
  templateUrl: './heatmap.component.html',
  styleUrls: ['./heatmap.component.scss']
})
export class HeatmapComponent implements OnInit, AfterViewInit {
  private map;
  private infoControl = leaflet.control();
  private legendControl = leaflet.control({position: 'bottomright'});
  private outOfStateControl = leaflet.control({position: 'bottomleft'});
  private mapLoaded = false;

  dataLoaded = false;
  geoData: {features: Array<any>} = {features: []};

  /**
   * Used to detect changes to metrics.
   */
  @Input() metricsSubject: Subject<any>;

  activeMembersMetrics;
  fipsMetrics: {id: string, skey: string, json: Object};
  fipsCodeRegNumMap = new Map<string, number>();
  outOfStateReg = 0;

  mapColors = [
    '#FFEDA0',
    '#FED976',
    '#FEB24C',
    '#FD8D3C',
    '#FC4E2A',
    '#E31A1C',
    '#BD0026',
    '#800026',
    '#67001F',
    '#4D0018'
  ];

  defaultSteps = [
    10,
    20,
    30,
    40,
    50,
    60,
    70,
    80,
    90,
    100
  ];

  mapOptions = {
    ZoomDelta: 0.25,
  }

  geoJson;
  maxLat: number;
  maxLon: number;
  minLat: number;
  minLon: number;
  boundPadding = 0.5;

  constructor(private stateService: StateService) { }

  ngOnInit(): void {
    this.watchForMetrics();
  }

  ngAfterViewInit(): void {
    this.stateService.getGeoJson().subscribe(data => {
      // State 28 -> MS
      const msData = data.features.filter(d => d['properties']['STATE'] === '28');
      msData.forEach(d => {
        d['geometry']['coordinates'].forEach((c: Array<[number, number]>) => {
          const coords = c[0];
          if (this.maxLon == null || this.maxLon < coords[0]) {
            this.maxLon = coords[0];
          }
          if (this.minLon == null || this.minLon > coords[0]) {
            this.minLon = coords[0];
          }
          if (this.maxLat == null || this.maxLat < coords[1]) {
            this.maxLat = coords[1]
          }
          if (this.minLat == null || this.minLat > coords[1]) {
            this.minLat = coords[1];
          }
        });
      });
      this.geoData = data;
      data.features = msData;
      this.createMap();
      this.addTiles();
      this.addGeoData(data);
      this.addInfoControl();
      this.addOutOfStateControl();
      this.applyBounds();
      this.mapLoaded = true;
    });
  }

  applyBounds() {
    const bounds = leaflet.latLngBounds(leaflet.latLng(this.maxLat + this.boundPadding, this.maxLon + this.boundPadding),
      leaflet.latLng(this.minLat - this.boundPadding, this.minLon - this.boundPadding));
    this.map.setMaxBounds(bounds);
    // Fit the map view to the specified bounds
    this.map.fitBounds(bounds);
  }

  addGeoData(data: any) {
    this.geoJson = leaflet.geoJson(data, {style: (feature) => this.countyStyle(feature), onEachFeature: (feature, layer) => {this.onEachFeature(feature, layer)}}).addTo(this.map);
  }

  updateMap() {
    this.geoJson.clearLayers();
    this.geoJson.addData(this.geoData);
    this.outOfStateControl.update();
  }

  createMap() {
    this.map = leaflet.map('map', this.mapOptions);
  }

  addTiles() {
    const tiles = leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });
    
    tiles.addTo(this.map);
  }

  /**
   * Used to add info control to top right of map
   */
  addInfoControl() {
    this.infoControl.onAdd = function (map) {
      this._div = leaflet.DomUtil.create('div', 'info-control');
      this.update();
      return this._div;
    }

    this.infoControl.update = (props) => {
      this.infoControlUpdate(props);
    };

    this.infoControl.addTo(this.map);
  }

  /**
   * Asynchronous update function triggered as part of the info control.
   * 
   * @param props - Properties of the feature
   */
  infoControlUpdate(props) {
    let registrants = 0;
    if (props) {
      registrants = this.fipsCodeRegNumMap.has(props.STATE + props.COUNTY) ? this.fipsCodeRegNumMap.get(props.STATE + props.COUNTY) : 0;
    }
    this.infoControl._div.innerHTML = '<h4>Registration Details</h4>' +  (props ?
      '<b>' + props.NAME + '</b><br />' + registrants + ' registrants'
      : 'Hover over an area.');
  }

  /**
   * Can be used to add a legend to the bottom right.
   * This is still a WIP and will require some work if added to a final product.
   */
  addLegendControl() {
    this.legendControl.onAdd = (map) => {
      const div = leaflet.DomUtil.create('div', 'info-control legend-control');

      for (var i = 0; i < this.mapColors.length; i++) {
        div.innerHTML +=
            '<i style="background:' + this.mapColors[i] + '"></i> ' +
            this.defaultSteps[i] + (this.mapColors[i + 1] ? '<br/>' : '+');
      }

      return div;
    }

    this.legendControl.addTo(this.map);
  }

  /**
   * Control added to the top left corner of the map showing number of out of state registrants.
   */
  addOutOfStateControl() {
    this.outOfStateControl.onAdd = (map) => {
      this.outOfStateControl._div = leaflet.DomUtil.create('div', 'info-control');
      this.outOfStateControl.update();
      return this.outOfStateControl._div;
    };

    this.outOfStateControl.update = (props) => {
      this.outOfStateControl._div.innerHTML = '<h4>Out of State Registration</h4><b>'
      + this.outOfStateReg + '</b> Registrants';
    };

    this.outOfStateControl.addTo(this.map);
  }

  /**
   * Can be used to mask the map view and show only the outline of the state / counties.
   */
  addWorldMask() {
    const worldGeoJSON = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [-180, -90],
                [180, -90],
                [180, 90],
                [-180, 90],
                [-180, -90]
              ]
            ]
          }
        }
      ]
    };
    leaflet.geoJson(worldGeoJSON, {style: this.worldStyle}).addTo(this.map);
  }

  countyStyle(feature) {
    return {
      fillOpacity: 0.7,
      weight: 1,
      opacity: 0.5,
      color: 'black',
      fillColor: this.getFeatureColor(feature)
    };
  }

  getFeatureColor(feature) {
    const props = feature.properties;
    const metricNum = this.fipsCodeRegNumMap.has(props.STATE + props.COUNTY) ? this.fipsCodeRegNumMap.get(props.STATE + props.COUNTY) : 0;
    const i = this.findNearestIndex(metricNum);
    return this.mapColors[i];
  }

  worldStyle(feature) {
    return {
      fillOpacity: 1,
      fillColor: 'white'
    }
  }

  highlightFeature(e) {
    const layer = e['target'];
    layer.setStyle({
      weight: 2
    });
    layer.bringToFront();
    this.infoControl.update(layer.feature.properties);
  }

  resetHighlight(e) {
    this.geoJson.resetStyle(e.target);
    this.infoControl.update();
  }

  zoomToFeature(e) {
    this.map.fitBounds(e.target.getBounds());
  }

  onEachFeature(feature, layer) {
    layer.on({
      mouseover: (e) => this.highlightFeature(e),
      mouseout: (e) => this.resetHighlight(e),
      click: (e) => this.zoomToFeature(e)
    });
  }

  /**
   * Handle changes related to changed / new metrics
   */
  watchForMetrics() {
    this.metricsSubject.subscribe((data: Array<any>) => {
      this.dataLoaded = false;
      data.forEach(m => {
        const item = m.Item;
        if (item) {
          if (item.skey && item.skey.includes('ACTIVE_MEMBERS')) {
            this.activeMembersMetrics = item;
          }
          if (item.skey && item.skey.includes('FIPS_DATA')) {
            this.fipsMetrics = item;
            this.populateFipsCodeRegNumMap();
          }
        }
      });
      this.findOutOfStateReg();
      if (this.mapLoaded) {
        this.updateMap();
      }
    });
  }

  populateFipsCodeRegNumMap() {
    this.fipsCodeRegNumMap = new Map();
    for (const key in this.fipsMetrics.json) {
      this.fipsCodeRegNumMap.set(key, this.fipsMetrics.json[key]);
    }
  }

  findNearestIndex(value: number): number {
    if (value === 0) {
      return 0;
    }

    const inStateFips = new Set();
    this.geoData.features.forEach(d => inStateFips.add(d.properties.STATE + d.properties.COUNTY));
    let maxNumInState = 0;
    this.fipsCodeRegNumMap.forEach((value, key) => {
      if (inStateFips.has(key)) {
        if (+value > maxNumInState) {
          maxNumInState = +value;
        }
      }
    });

    if (maxNumInState === 0) {
      return 0;
    }

    if (value === maxNumInState) {
      return 9;
    }

    const ratio = Math.min(1, value / maxNumInState);
    const floorValue = Math.floor(ratio * 10);
    return floorValue;
  }

  findOutOfStateReg() {
    const inStateFips = new Set();
    let numInState = 0;
    this.geoData.features.forEach(d => inStateFips.add(d.properties.STATE + d.properties.COUNTY));
    this.fipsCodeRegNumMap.forEach((value, key) => {
      if (inStateFips.has(key)) {
        numInState += value;
      }
    });
    this.outOfStateReg = this.activeMembersMetrics.count - numInState;
  }
}
