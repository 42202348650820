// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// DEV
/* export const environment = {
   production: false,
   name: "dev",
   host: "https://kp3v06aiqh.execute-api.us-east-2.amazonaws.com/dev",
   userPoolId: 'us-east-2_P33mhlmc8',
   userPoolWebClientId: '1ft55vbm49rercblapt459d669',
   gaTrackingId: 'G-6DTECKFE8F',
   url: "https://talent.steering.dev.nsparc.com"
}; */
// QA
  export const environment = {
          production: false,
          name: "qa",
          host: "https://eqtspkfqza.execute-api.us-east-2.amazonaws.com/qa",
          userPoolId: 'us-east-2_6VqVKxqEW',
          userPoolWebClientId: '6fe7lpfp93q2bp1t7clkk8dkhj',
          gaTrackingId: 'G-6DTECKFE8F',
          url: "https://talent.steering.qa.nsparc.com"
 }; 
//UAT
/*  export const environment = {
         production: false,
         name: "uat",
         host: "https://1s8vpnj6pa.execute-api.us-east-1.amazonaws.com/uat",
         userPoolId: 'us-east-1_AtwTgZnxm',
         userPoolWebClientId: '6oud95hrmqqo215n2fm5mpdocb',
         gaTrackingId: 'G-9QXHK1ZG69',
         bulkMessageMemberCount: 50,
         url: "https://microsite-uat.nsparc.org"
     }; */

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
