import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  @Input() message = 'Loading ...';
  @Input() bigSize = false;
  @Input() textAlign: 'center' | 'left' | 'right' = 'center';

  constructor() {
  }

  ngOnInit() {
  }

}
