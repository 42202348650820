import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MemberService } from '@app/services/member.service';
import { MessageService } from '@app/services/message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from '@app/shared/confirm-dialog/confirm-dialog.component';
import { AppCookieService } from '@app/services/app-cookie.service';
import { Subscription, Subject } from 'rxjs';
import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import { environment } from '@env/environment';
import { ToastrService } from 'ngx-toastr';
import { MessageType } from '@app/constants/MessageType';
import { CanDeactivateComponent } from '@app/shared/interfaces/can-deactivate.interface';
import { NotificationRequestModel } from '@app/models/notification-request.model';
import { NotificationService } from '@app/services/notification.service';
import { NotificationSearchModel } from '@app/models/notification-search.model';
import { NotificationSearchType } from '@app/constants/NotificationSearchType';
import { MessageStatus } from '@app/constants/MessageStatus';

@Component({
    selector: 'app-create-message',
    templateUrl: './create-message.component.html',
    styleUrls: ['./create-message.component.scss']
})
export class CreateMessageComponent implements OnInit, OnDestroy, CanDeactivateComponent {
    smsMaxLength = 160;
    emailMaxLength = 1000;
    subjectMaxLength = 100;
    debounceTimeout = 500; //ms
    mod = {}
    reachedLastMemeber = false;
    hasPerformedFocusSearch = false;
    sending = false;
    searching = false;
    messagePending = false;

    membersPage = 1;
    msgTypeEnum = MessageType;
    messageType: MessageType = MessageType.E;
    keyword = "";
    messageTypeSubscription: Subscription;
    message: {
        content: string,
        count: number,
        employer_name: string,
        id: string,
        reason?: string,
        request_date: string,
        send_mode: string,
        sent_date?: string,
        skey: string,
        status: string,
        subject?: string
    };
    messageStatus = MessageStatus;
    sendForApprovalText = 'Send For MDES Approval';
    confirmationModalText = "Are you sure you want to send this message for MDES approval?"
    + " You will receive an email if the message is approved or rejected.";

    formOpen = false;
    messageForm = this.fb.group({
        messageType: this.fb.control('', [Validators.required]),
        messageBody: this.fb.control('', [Validators.required]),
        messageSubject: this.fb.control('')
    });



    constructor(private router: Router, private fb: FormBuilder, private memberService: MemberService, private messageService: MessageService, private modalService: NgbModal,
        private notificationService: NotificationService, private cookie: AppCookieService, private gaService: GoogleAnalyticsService, private toastr: ToastrService) { }

    ngOnInit(): void {
        this.messageTypeSubscription = this.memberService.messageType.subscribe(messageType => { if (messageType) this.messageType = messageType });
        this.setMessageType(this.messageType);
        this.searchForPending(true);

        // Modules to include in Quill Editor instance
        this.mod = {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote', 'code-block'],

                [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                [{ 'direction': 'rtl' }],                         // text direction

                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                [{ 'font': [] }],
                [{ 'align': [] }],

                ['clean'],                                         // remove formatting button

                ['link', 'image']                         // link and image, video
            ]
        };
    }

    searchForPending(firstSearch?: boolean) {
        this.searching = true;
        const searchModel = new NotificationSearchModel();
        searchModel.searchType = NotificationSearchType.PENDING_EMPLOYER_REQUEST;

        this.notificationService.searchBulkNotifications(searchModel).subscribe(data => {
            if (!!data && !(data instanceof Array)) {
                this.message = data;
                this.messagePending = true;
            }
            this.searching = false;
            if (firstSearch) {
                this.messageForm.controls['messageType'].setValue(this.messageType);
                this.messageForm.controls['messageType'].markAsTouched();
            }
        });
    }

    ngOnDestroy() {
        this.messageTypeSubscription.unsubscribe();
    }

    allowFields(allow: boolean) {
        if (allow) {
            this.messageForm.controls.messageType.enable();
            this.messageForm.controls.messageBody.enable();
            this.messageForm.controls.messageSubject.enable();
        } else {
            this.messageForm.controls.messageType.disable();
            this.messageForm.controls.messageBody.disable();
            this.messageForm.controls.messageSubject.disable();
        }
    }

    dashboard() {
        this.router.navigateByUrl('/dashboard');
    }

    setMessageType(type: MessageType) {
        this.messageType = type;

        if (this.formOpen) {

            this.messageForm.controls.messageSubject.setValue("");
            this.messageForm.controls.messageBody.setValue("");

            if (type === MessageType.S) {
                this.messageForm.controls.messageSubject.clearValidators();
                this.messageForm.controls.messageBody.clearValidators();
                this.messageForm.controls.messageBody.setValidators([Validators.required, Validators.maxLength(this.smsMaxLength)]);
                this.gaService.logEvent("SendMessage", "ChangeMessageType", "SMS");
            } else {
                this.messageForm.controls.messageSubject.setValidators([Validators.required, Validators.maxLength(this.subjectMaxLength)]);
                this.messageForm.controls.messageBody.clearValidators();
                this.messageForm.controls.messageBody.setValidators([Validators.required, Validators.maxLength(this.emailMaxLength)]);
                this.gaService.logEvent("SendMessage", "ChangeMessageType", "Email");
            }

            this.messageForm.controls.messageSubject.updateValueAndValidity();
            this.messageForm.controls.messageBody.updateValueAndValidity();

            this.messageForm.controls.messageSubject.markAsPristine();
            this.messageForm.controls.messageBody.markAsPristine();

            this.messageForm.controls.messageSubject.markAsUntouched();
            this.messageForm.controls.messageBody.markAsUntouched();

        } else {
            this.formOpen = true;
        }
    }

    onSubmit() {
        const dialogRef = this.modalService.open(ConfirmDialogComponent, {centered: true});
        dialogRef.componentInstance.pageBody = this.confirmationModalText;
        dialogRef.componentInstance.confirmOptionText = this.sendForApprovalText;
        dialogRef.componentInstance.showCloseButton = true;
        dialogRef.componentInstance.useYesNo = false;
        dialogRef.componentInstance.confirm$.asObservable().subscribe(c => {
            if (c) {
                let action = "Send " + (this.messageType === MessageType.S ? 'SMS' : 'Email');
                this.gaService.logEvent("SendMessage", action, "Send Message");
        
                const request = new NotificationRequestModel();
                request.messageType = this.messageForm.controls.messageType.value;
                request.subject = this.messageForm.controls.messageSubject.value ? this.messageForm.controls.messageSubject.value : null;
                request.body = this.messageForm.controls.messageBody.value;
                request.url = environment.url;
        
                this.sending = true;
                this.notificationService.requestNotification(request).subscribe(res => {
                    if (!res.error) {
                        this.toastr.success('Your request has been sent!');
                        this.messageForm.reset();
                        this.searchForPending();
                    } else {
                        this.toastr.error('Please wait a few minutes and try again.');
                        console.log('Send Message Error: ' + JSON.stringify(res.error.message));
                    }
                    this.sending = false;
                },
                error => {
                    console.log(error);
                    if (error.error && error.error.validationError && error.error.message) {
                        this.toastr.error(error.error.message);
                    } else {
                        this.toastr.error('Please wait a few minutes and try again.');
                    }
                    this.sending = false;
                });
            }
        });
    }

    isInfoEntered() {

        let hasSubject = this.messageForm.controls.messageSubject.value !== null && this.messageForm.controls.messageSubject.value.length > 0;
        let hasBody = this.messageForm.controls.messageBody.value !== null && this.messageForm.controls.messageBody.value.length > 0;

        return hasSubject || hasBody;
    }

    confirmChangeMessageType(type: MessageType) {

        if (this.formOpen && this.isInfoEntered() && this.isMessageTypeChanged(type)) {
            const confirmDialog = this.modalService.open(ConfirmDialogComponent, { centered: true });
            confirmDialog.componentInstance.pageTitle = "";
            confirmDialog.componentInstance.pageBody = "Changing the message type will clear the message. Do you wish to continue?";
            confirmDialog.componentInstance.showCloseButton = false;
            confirmDialog.componentInstance.confirm$.asObservable().subscribe(res => {
                if (res) {
                    this.setMessageType(type);
                } else {
                    this.messageForm.controls['messageType'].setValue(this.messageType);
                }
            })
        } else {
            if (this.isMessageTypeChanged(type))
                this.setMessageType(type);
        }
        this.formOpen = true;
    }

    isMessageTypeChanged(type: string) {
        return this.messageType !== type;
    }

    confirmBackNav() {

        if (this.isInfoEntered()) {
            const confirmDialog = this.modalService.open(ConfirmDialogComponent, { centered: true });
            confirmDialog.componentInstance.pageTitle = "";
            confirmDialog.componentInstance.pageBody = "Leaving this page will clear the form and will not send a message. Do you wish to continue?";
            confirmDialog.componentInstance.showCloseButton = false;
            confirmDialog.result.then(() => {
                this.router.navigateByUrl('/messages');
            }, () => undefined);
        } else {
            this.router.navigateByUrl('/messages');
        }
    }

    canDeactivate() {
        return !this.isInfoEntered();
    };
}
