import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingComponent } from './landing/landing.component';
import { LoginComponent} from './login/login.component';
import { WelcomeRoutingModule } from './welcome-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CookieService } from 'ngx-cookie-service';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  declarations: [LandingComponent,LoginComponent, ForgotPasswordComponent, ResetPasswordComponent],
  imports: [
    CommonModule,
    WelcomeRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    SharedModule
  ],
  providers:[CookieService],
  exports: [LandingComponent,LoginComponent,ForgotPasswordComponent]
})
export class WelcomeModule { }
