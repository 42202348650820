import { NotificationSearchType } from "@app/constants/NotificationSearchType";
import { BaseSearchModel } from "./base-search.model";
import { MessageType } from "@app/constants/MessageType";
import { MessageStatus } from "@app/constants/MessageStatus";

export class NotificationSearchModel extends BaseSearchModel {
    searchType: NotificationSearchType;
    skey: string = null;
    messageType: MessageType;
    messageStatus: MessageStatus;
    onAfterDate: Date;
}