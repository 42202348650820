export enum Endpoint {
    AID_YEAR = '/aid-year',
    MEMBER = '/member',
    DELETE = '/delete',
    HOUSEHOLD_INFO = '/household-info',
    PARTICIPATION = '/participation',
    REPRESENTATIVES = '/representatives',
    STUDENTS_EXISTS = 'students/exists',
    USER = '/user',
    PRINCIPAL = 'principal',
    USERINFO = 'userinfo',
    METRICS = '/metrics',
    RETRIEVE_METRICS = '/retrievemetrics',
    PENDING_USERS = '/users/pending',
    APPROVED_USERS = '/users/approved',
    INSTITUTIONS = '/institutions',
    MAJORS = 'majors',
    RACES = 'races',
    DEGREES = 'degrees',
    CLASSIFICATIONS = 'classifications',
    CERTIFICATION = 'certification',
    COUNSELORS = '/counselors',
    COUNTIES = 'counties',
    NOT_CERTIFICATE = '/not-certificate',
    HIGH_SCHOOLS = 'high-schools',
    SCHOOL_DISTRICTS = 'school-districts',
    CHECK_VERIFIED = 'password/isVerified',
    ACCEPT_USER = 'user/acceptUser',
    APPLICATION = 'app',
    AID_YEARS = '/aid-years',
    SAVE_STUDENT_INFO = '/save-student-info',
    USER_UPDATE = 'user/update',
    REQUEST_ACCOUNT = 'user/request-account',
    GET_STUDENT_INFO = '/student-info',
    CONTACT_INFO_FIRST = '/contact-info/first',
    CONTACT_INFO_SECOND = '/contact-info/second',
    PRIOR_COLLEGE = '/prior-college-information',
    SAVE_STATISTICAL_INFO = '/save-statistical-info',
    GET_STATISTICAL_INFO = '/get-statistical-info',
    GET_MARITAL_STATUSES = '/get-marital-statuses',
    DRIVER_LICENSE_INFO = '/driver-license-info',
    TIME_DEGREE = '/time-status-degree-date',
    HOUSEHOLD_VERIFICATION = '/household-verification',
    EDUG = '/edug',
    EDUCSA = '/educsa',
    EDGTS = '/edgts',
    GRADUATE = '/graduate',
    UNDERGRADUATE = '/undergraduate',
    DOCTORATE = '/doctorate',
    COLLEGES = '/colleges',
    CURRENT = '/current',
    CLASSIFICATION_DEGREES = '/classification-degree',
    TES = '/tes-info',
    TES_WWTS = '/tes-wwts',
    INSTITUTION_TO_ATTEND = '/institutions-to-attend',
    DISCLAIMER = '/disclaimer',
    DISCLAIMER_SECOND = '/disclaimer-second',
    HIGH_SCHOOL_INFORMATION = '/high-school-information',
    MAJOR = '/major-programs',
    C2C = '/c2c',
    LAW_QUALIFICATION = '/law-qualification',
    EDUCATIONAL_RECORD = '/educational-record',
    EDUCATIONAL_RECORD_MEMBER = '/member',
    CHECK_STEP = '/check-step',
    CHECK_CONDITION = '/check-condition-step',
    CHECK_PROGRAM_STEP = '/check-program-step',
    LOAN = '/loan',
    SERVICING_AGENT = 'servicing-agent',
    CONFIRMATION = '/confirmation',
    EMAIL_VERIFICATION = '/email-verification',
    STUDENT_VERIFICATION = '/student',
    GET_USERNAME = '/get-username',
    CHECK_USERNAME = '/check-username',
    ACCOUNT_VERIFICATION = '/account-verification',
    GET_ROLE = '/get-role',
    REPORT = '/report',
    SCHOOL = '/school',
    DISTRICT = '/district',
    INSTITUTION = '/institution',
    CURRENT_SUBMITTED = 'app/current-submitted',
    EXISTS_CURRENT_APP = '/exists-current-app',
    AVAILABILITY_DATE = '/availability-date',
    STUDENTS = '/students',
    EXCEL = '/excel',
    DETAILS_PREVIEW = '/details-preview',
    INST_ENROLLMENTS = '/inst-enrollments',
    INST_GRADES = '/inst-grades',
    APPLICANT = 'applicant',
    AWARDS = 'awards',
    HIGH_SCHOOL_GPA = '/high-school-gpa',
    ACADEMIC = '/academic',
    SETTINGS = '/settings',
    DOCUMENTS = '/documents',
    MESSAGES = '/messages',
    MILESTONES = '/milestones',
    APP_DASHBOARD = '/app-dashboard',
    CHECK_APPLY = '/check-apply',
    REPRESENTED_STUDENTS = '/represented-students',
    STUDENT = '/student',
    REPRESENTATIVE = '/representative',
    CHOSEN_STUDENT = '/chosen-student',
    EMAIL = '/email',
    SMS = '/sms',
    CHANGE_OWN_EMAIL = '/email/change',
    CHANGE_ANY_USER_EMAIL = '/email/admin',
    VERIFY = '/verify',
    RESEND = '/resend',
    QUICK_SEARCH = '/quick-search',
    PROGRAM_TYPES = '/program-types',
    TOTAL_AWARDS_COUNT = '/total-awards-count',
    SUM_PAID_AWARDS_AMOUNT = '/sum-paid-awards-amount',
    SUM_OFFERED_AWARDS_AMOUNT = '/sum-offered-awards-amount',
    PASSWORD_SEND = '/password/send/',
    ALL_PROGRAM_TYPES = '/all-program-types',
    AUDIT = '/audit',
    USERS_AUDIT = '/user',
    DETAILS = '/details',
    CLONE_APPLICATION = '/clone-application',
    UNREAD_COUNT = '/unread-count',
    DRIVER_LICENSE_VERIFICATION = '/dps/verification',
    EDUC_CODE = '/educ-code',
    MAINTENANCE = '/maintenance',
    BY_DISTRICT = '/by-district',
    IS_NISSAN_UPDATE = '/is-nissan-update',
    CONVERT_TO_NISSAN = '/convert-to-nissan',
    SELF_CERTIFICATION = '/self-certification',
    VERIFICATION_REQUEST = 'verification-request',
    CHECK_GRADUATE_APPLICATION = '/check-graduate-application',
    PARTICIPATION_AGREEMENT = '/participation-agreement',
    SEARCH = '/search',
    NOTIFICATION = '/notification',
    REQUEST = '/request'
}
