import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AnonymousGuard implements CanActivate, CanActivateChild {
    
  constructor(private cookie: CookieService, private router: Router){      
  }  
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean{
          if ( this.cookie.get('token') && state.url !="/logout" ) {
              this.router.navigate(['dashboard']);
              return false;
          }
          return true;
  }
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.canActivate(route, state);
  }
  
}
