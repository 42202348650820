<div class="card card-body app-line d-print-none"></div>
<div class="dashboard">
	<div class="d-flex flex-nowrap p-2 d-print-none shadow-sm header-shadow">
		<div class="pr-0 align-self-center" [class.invisible]="!isMobile">
			<!--       <button class="btn" (click)="matsidenav.toggle()"><i class="fa fa-bars fa-2x"></i></button> -->
		</div>
		<div class="flex-shrink-1 align-self-center px-2">
			<a routerLink="/"> <img src="../../assets/employer_logo.svg" class="img-fluid maapp-logo"
					alt="Employer logo">
			</a>
		</div>
		<div class="flex-grow-1">&nbsp;</div>
		<div class="flex-nowrap">
			<app-header [principal]="principal" [isMobile]="isMobile"></app-header>
		</div>
	</div>
	<div class="row">
		<div class="col-xl-1"></div>
		<div class="col-xl">
			<div class="sidenav-context p-3 d-flex flex-column">
				<router-outlet></router-outlet>
			</div>
		</div>
		<div class="col-xl-1"></div>
	</div>
</div>