<div class="container">
	<div class="row ">
		<div class="border-container col-12 ">
			<div class="pb-2 mt-4 mb-2 ">
				<h4 class="app-blue font-weight-bold text-center">Reset Password</h4>
				<div class="row">
					<div class="col">
						<div [hidden]="!requestError" class="alert alert-danger">
							<div>Please confirm that the email address is correct and that the email address has been verified.</div>
						</div>
					</div>
				</div>
				<div class="app-blue font-weight-bold text-left">A recovery code will be sent to your email. If you do not receive an email within the next hour, please double-check your email address and
					try again.</div>
			</div>
		</div>
	</div>
	<div class="row " [hidden]="submitted">
		<div class="col">
			<div class="container">
				<form [formGroup]="forgotPasswordForm" (submit)="submit()" class="m-auto col-sm-8">
					<div class="row">
						<input placeholder="Email" type="text" formControlName="email" class="form-control" id="email">
						<div [hidden]="forgotPasswordForm.controls.email.status==='VALID' || forgotPasswordForm.controls.email.pristine" class="alert alert-danger col mb-0">
							<div *ngIf="forgotPasswordForm.controls.email.errors?.required">Email is required</div>
							<div *ngIf="forgotPasswordForm.controls.email.errors?.pattern">Please enter a valid email</div>
						</div>
					</div>
					<div class="row justify-content-md-center mt-2">
						<button class="btn btn-primary" type="submit" [promiseBtn]="requestInProgress" [disabled]="forgotPasswordForm.status === 'INVALID'">Email my code</button>
					</div>
				</form>
			</div>
		</div>
	</div>
	<!-- after successful submit -->
	<div class="row text-primary font-weight-bold" [hidden]="!submitted">
		<div class="col">
			<p>If an account exists, an email will be sent to {{this.forgotPasswordForm.controls.email.value}}. Please click the recovery link inside the email. If you do not receive an email within the
				next hour, please double-check your email address and try again.</p>
		</div>
	</div>
</div>