<div class="card-body">
	<form [formGroup]="messageSearchForm">
		<div class="row">
			<div class="col-lg-3 col-12">
					<label for="date" class="font-weight-bold d-block">Sent on or after:</label>
					<div class="d-flex align-items-center">
						<input placeholder="Select Date" matInput class="form-control me-2" id="date" formControlName="date" [matDatepicker]="datePicker"/>
						<mat-datepicker-toggle class="flex-grow-1" matSuffix [for]="datePicker"></mat-datepicker-toggle>
						<mat-datepicker #datePicker></mat-datepicker>
					</div>
			</div>
			<div class="col-lg-3 col-12">
				<label for="messageType" class="font-weight-bold">Message Type:</label>
				<ng-select formControlName="messageType" [items]="messageTypeItems" placeholder="Message Type"
				[clearable]="false" bindLabel="key" bindValue="value">
					<ng-template ng-option-tmp let-item="item">
						{{item.key}}
					</ng-template>
				</ng-select>
			</div>
			<div class="col-lg-3 col-12">
				<label for="messageStatus" class="font-weight-bold">Status:</label>
				<ng-select formControlName="messageStatus" [items]="messageStatusItems" bindLabel="key" bindValue="value"
				[clearable]="false" placeholder="All">
					<ng-template ng-option-tmp let-item="item">
						{{item.key | titlecase}}
					</ng-template>
				</ng-select>
			</div>
			<div class="col-lg-3 col-12 d-flex justify-content-center align-items-end">
				<div class="mt-2">
					<button class="btn btn-link btn-reset" (click)="onResetClick()">Reset</button>
					<button class="btn btn-primary text-nowrap" [promiseBtn]="searching"
						(click)="onSearchClick()">Search</button>
				</div>
			</div>
		</div>
	</form>
</div>
<div class="my-2 card overflow-auto">
	<div class="card-body">
		<div *ngIf="loading" class="d-flex loading-card justify-content-center align-items-center">
			<app-loading class="d-flex loading-container" [bigSize]="true"> </app-loading>
		</div>
		<mat-accordion *ngIf="!loading">
			<cdk-virtual-scroll-viewport itemSize="50"
				(scrolledIndexChange)="scrollHandler($event,(messages.Items[messages.Items.length-1]))">
				<mat-expansion-panel *cdkVirtualFor="let item of messages.Items;" class="my-1">
					<mat-expansion-panel-header class="my-1">
						<mat-panel-title *ngIf="item.send_mode">
							<div class="col-2 font-weight-bold text-primary">
								{{item.request_date | date: 'yyyy-MM-dd'}}
								<br/>
								<span class="font-weight-normal text-dark">{{messageType[item.send_mode]}}</span>
							</div>
							<div class="col-2 font-weight-bold text-primary">
								Status
								<br/>
								<span class="font-weight-normal" [ngClass]="{'text-success': item.status === messageStatus.APPROVED,
								'text-danger': item.status === messageStatus.REJECTED, 'text-secondary': item.status === messageStatus.PENDING}">
									{{item.status | titlecase}}</span>
							</div>
							<div class="col-2 font-weight-bold text-primary">
								<span *ngIf="!!item.sent_date">
									Sent On:
									<br/>
									<span class="font-weight-normal text-dark">{{item.sent_date | date: 'yyyy-MM-dd'}}</span>
								</span>
							</div>
							<div class="col-2 font-weight-bold text-primary">
								Members
								<br/>
								<span class="font-weight-normal text-dark">{{item.count}}</span>
							</div>
							<div class="col-4 font-weight-bold text-primary">
								<span *ngIf="!!item.subject">
									Subject
									<br/>
									<span
									class="d-inline-block font-weight-normal text-dark text-hidden-overflow-long">
										{{item.subject}}
									</span>
								</span>
							</div>
						</mat-panel-title>
					</mat-expansion-panel-header>
						<div *ngIf="!!item.subject" class="col-12 font-weight-bold text-primary">
							Subject:
						</div>
						<div *ngIf="!!item.subject" class="col-12 card-body border ml-3">
							{{item.subject}}
						</div>
						<div class="col-12 font-weight-bold text-primary">
							Body:
						</div>
						<div class="col-12 card-body border ml-3">
							<span [innerHTML]="item.content"></span>
						</div>
						<div *ngIf="!!item.reason" class="col-12 font-weight-bold text-primary">
							Reason:
						</div>
						<div *ngIf="!!item.reason" class="col-12 card-body border ml-3">
							{{item.reason}}
							</div>
				</mat-expansion-panel>
			</cdk-virtual-scroll-viewport>
		</mat-accordion>
	</div>
</div>