import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StateModel } from '@app/models/state.model';
//import { environment } from '@env/environment';
//import { Endpoint } from '@app/constants/Endpoint';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  constructor(private http: HttpClient) {
  }

  getAll(): Observable<StateModel[]> {
//      return this.http.get<StateModel[]>(environment.host + `${Endpoint.API}${Endpoint.STATES}`);
      return this.http.get<StateModel[]>("../../../assets/states.json");
  }

  getGeoJson(): Observable<{type: string, features: Array<{}>}> {
    return this.http.get<{type: string, features: Array<{}>}>('../../../assets/gz_2010_us_050_00_5m.json');
  }
}
