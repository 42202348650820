export enum Path {
    CURRENT_SUBMITTED_APP = 'current-app-info',
    CLASSIFICATION_AND_DEGREE = 'classification-degree',
    EMPTY = '',
    LOGIN = 'login',
    DASHBOARD = 'dashboard',
    MEMBER_DETAIL = "member-detail",
    MESSAGES = 'messages',
    MESSAGE_TOGGLE = 'message-toggle',
    SEND_MESSAGES = 'send-messages',
    ACCOUNT = 'account',
    USER = 'user',
    SEARCH = 'search',
    CREATE = 'create',
    DETAILS = 'details',
    ENABLE = 'enable',
    REQUEST = 'request',
    STUDENT = 'student',
    STUDENTS = 'students',
    SUPERS_AND_HEADS = 'superintendent-headmaster',
    INSTITUTION_FAA = 'faa',
    INSTITUTION_DIR = 'director',
    COUNSELOR = 'counselor',
    FORGOT_PASSWORD = 'forgot/password',
    RESET_PASSWORD = 'reset',
    FORGOT_USERNAME = 'forgot/username',
    INSTITUTION_DIRECTORS = 'directors',
    ACTIVITY_LOG = 'activity',
    SETTINGS = 'settings',
    REPORTS = 'reports',
    HIGH_SCHOOL = 'high-school',
    HIGH_SCHOOL_REPORT = 'reports/high-school',
    SCHOOL_DISTRICT = 'school-district',
    SCHOOL_DISTRICT_REPORT = 'reports/school-district',
    INSTITUTION = 'institution',
    INSTITUTION_REPORT = 'reports/institution',
    COUNSELORS = 'counselors',
    FINANCIAL_AID_ADMINISTRATORS = 'faa',
    USERS = 'users',
    APPLICATION = 'application',
    STUDENT_INFO = 'applicant-information',
    LINK_EXPIRED = 'link-expired',
    LOGOUT = 'logout',
    CONTACT_INFO_1 = 'contact-information-1',
    CONTACT_INFO_2 = 'contact-information-2',
    STATISTICAL_INFO = 'statistical-information',
    DRIVER_LICENSE_INFO = 'drivers-license',
    WWAR = 'wwar',
    INSTITUTION_TO_ATTEND = 'institutions-to-attend',
    PRIOR_COLLEGE = 'prior-college-information',
    HIGH_SCHOOL_INFORMATION = 'high-school-information',
    TIME_DEGREE = 'time-degree',
    DISCLAIMER = 'disclaimers-1',
    GTS = 'gts',
    CSA = 'csa',
    DISCLAIMER_SECOND = 'disclaimers-2',
    HOUSEHOLD_VERIFICATION = 'household-verification',
    CNDT = 'cndt',
    SLPL = 'slpl',
    EDUCATIONAL_RECORDS = 'education-records-release',
    UNDERGRADUATE = 'undergraduate',
    TES_WWTS = 'tes-wwts',
    TES1 = 'tes-1',
    TES2 = 'tes-2',
    GRADUATE = 'graduate',
    NTSPM = 'ntspm',
    DOCTORATE = 'doctorate',
    NTSPP = 'ntspp',
    MAJOR = 'major-programs',
    C2C = 'c2c',
    LAW_QUALIFICATION = 'law-qualification',
    SUMMARY_APPLICATION = 'summary',
    LOAN = 'loan',
    MTLR = 'MTLR',
    CONFIRMATION = 'confirmation',
    VERIFY = 'verify',
    AVAILABILITY_DATES = 'availability-dates',
    SUBMIT = 'submit',
    SREB = 'sreb',
    STUDENT_REPRESENTATIVE = 'representative',
    USER_APPLICATION = 'user-application',
    NEW_EMAIL = 'new-email',
    UPDATE_EMAIL = 'update-email',
    AWARDS = 'awards',
    BROADCAST_MESSAGES = 'broadcast',
    EDIT = 'edit',
    APP_UNAVAILABLE = 'unavailable',
    ERROR = 'error',
    PARTICIPATION_AGREEMENT = 'participation-agreement'
}
