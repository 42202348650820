import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NavigationCancel, Router } from '@angular/router';

@Component({
  selector: 'app-message-toggle',
  templateUrl: './message-toggle.component.html',
  styleUrls: ['./message-toggle.component.scss']
})
export class MessageToggleComponent implements OnInit, AfterViewInit {

  selectedOption: string;

  constructor(private router: Router) { }

  ngOnInit(): void {
    const url = this.router.url;
    const segments = url.split('/');
    if (segments.length > 0) {
      this.selectedOption = segments[segments.length - 1];
    }

    // Watch for cancelling navigation from guard
    this.router.events.subscribe(e => {
      if (e instanceof NavigationCancel) {
        const urlParts = this.router.url.split('/');
        const urlEnd = urlParts[urlParts.length - 1]
        this.selectedOption = urlEnd;
      }
    });
  }

  ngAfterViewInit(): void {
  }

}
