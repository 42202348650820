import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RecaptchaComponent } from 'ng-recaptcha';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class CaptchaService {

  constructor(private http: HttpClient) { }
  
  checkCaptcha(response:any): Observable<any> {
      return this.http.post<any>( environment.host +"/recaptcha", response);
  }
  
  onDestroy(): void {
      // The workaround for the ng-recaptcha error “zone.js: Unhandled Promise rejection”
      // Please see more details on https://github.com/DethAriel/ng-recaptcha/issues/123
      RecaptchaComponent.prototype.ngOnDestroy = function () {
          if (this.subscription) {
              this.subscription.unsubscribe();
          }
      }
  }
}
