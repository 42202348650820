export enum MessageStatus {
    /**
     * Approved Message
     */
    APPROVED = 'APPROVED',

    /**
     * Rejected Message
     */
    REJECTED = 'REJECTED',

    /**
     * Pending Message
     */
    PENDING = 'PENDING'
}