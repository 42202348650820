import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Component( {
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html'
} )
export class ConfirmDialogComponent implements OnInit {

    @Input() pageTitle = '';
    @Input() pageBody = '';
    @Input() useYesNo = true;
    @Input() showCloseButton = true;
    @Input() confirmOptionText = "Confirm";
    @Input() cancelOptionText = "Cancel";

    confirm$: Subject<boolean> = new Subject<boolean>();

    constructor( public modal: NgbActiveModal ) {
    }

    ngOnInit() {
        if ( this.useYesNo ) {
            this.confirmOptionText = "Yes";
            this.cancelOptionText = "No";
        }
    }

    cancel() {
        this.confirm$.next(false);
        this.modal.dismiss('Cancel');
    }

    confirm() {
        this.confirm$.next(true);
        this.modal.close(true);
    }

    //  getAnalyticEvent(): string {
    //    return 'Delete - ' + this.pageTitle;
    //  }
}
