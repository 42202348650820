<div class="row mb-2">
	<div class="col-lg-4 col-12 d-flex justify-content-center justify-content-sm-start">
		<button class="btn btn-outline-primary text-nowrap" [routerLink]="'/dashboard'">
			<fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
			Back to Dashboard
		</button>
	</div>
	<div class="col-lg-4 col-12 text-center">
		<div class="stats-main-number font-weight-bold mt-2">Messages</div>
	</div>
    <div class="col-lg-4"></div>
</div>
<div class="row">
	<div class="col-12 text-center">
		<mat-button-toggle-group name="messageToggle" aria-label="Message Toggle" [(ngModel)]="selectedOption">
			<mat-button-toggle value="send-messages" routerLink="./send-messages">New Message</mat-button-toggle>
			<mat-button-toggle value="messages" routerLink="./messages">Approved and Rejected</mat-button-toggle>
		</mat-button-toggle-group>
	</div>
</div>
<router-outlet></router-outlet>